import axios from 'axios';

import { BookingBaseService } from '@yojee/api/baseService/bookingBaseService';
import { addVersionHeader } from '@yojee/api/common/versionHeaderHelper';

import { showSessionExpiredDialog, signOut } from '../sagas/login/loginActions';
import { parseJwt } from '../utils';

const CancelToken = axios.CancelToken;

//	add interceptors for axios request/response to handle jwt
axios.interceptors.request.use(
  async (config) => {
    //	for public APIs and APIs involving `auth`, no need to include access token
    if (['public/', 'auth/'].some((key) => config.url && config.url.indexOf(key) > -1)) {
      return config;
    }

    const refreshTokenExpiry = localStorage.getItem('BOOKING-PAGE-REFRESH_TOKEN_EXPIRY');
    const timeNow = new Date().getTime() / 1000;
    //	if refresh token is about to expire, cancel API call and log user out
    if (refreshTokenExpiry && refreshTokenExpiry - 2 * 60 < timeNow) {
      //	if refresh token is expired, sign user out and display Session Expired dialog
      window.bookingReduxStore.dispatch(signOut());
      window.bookingReduxStore.dispatch(showSessionExpiredDialog());
      //	cancel the request
      return {
        ...config,
        cancelToken: new CancelToken((cancel) => cancel('Cancel request due to expired session')),
      };
    }

    const accessTokenExpiry = localStorage.getItem('BOOKING-PAGE-ACCESS_TOKEN_EXPIRY');
    //	refresh access token 2 minutes before token is expired
    if (accessTokenExpiry && accessTokenExpiry - 2 * 60 < timeNow) {
      if (config.url !== `${BookingBaseService.getUmbrellaApiHostUrl()}/public/jwt/auth_token`) {
        try {
          const data = await getNewJwt();
          const token = data && data['access_token'];
          config.headers['Authorization'] = `Bearer ${token}`;
        } catch (error) {
          Promise.reject(error);
        }
      }
      return config;
    }

    const accessToken = localStorage.getItem('BOOKING-PAGE-ACCESS_TOKEN');
    if (accessToken) {
      config.headers['Authorization'] = 'Bearer ' + accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const getNewJwt = () => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BookingBaseService.getUmbrellaApiHostUrl()}/public/jwt/auth_token`, {
        refresh_token: localStorage.getItem('BOOKING-PAGE-REFRESH_TOKEN'),
      })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          //	process and store JWT data in localStorage
          storeJwtData(res.data);
          resolve(res.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const storeJwtData = (jwtData) => {
  if (!jwtData) return;
  const accessToken = jwtData['access_token'] || null;
  const refreshToken = jwtData['refresh_token'] || null;
  if (accessToken) {
    const accessTokenData = parseJwt(accessToken) || { exp: null };
    const { exp } = accessTokenData;
    localStorage.setItem('BOOKING-PAGE-ACCESS_TOKEN', accessToken);
    localStorage.setItem('BOOKING-PAGE-ACCESS_TOKEN_EXPIRY', exp);
  }

  if (refreshToken) {
    const refreshTokenData = parseJwt(refreshToken) || { exp: null };
    const { exp } = refreshTokenData;
    localStorage.setItem('BOOKING-PAGE-REFRESH_TOKEN', refreshToken);
    localStorage.setItem('BOOKING-PAGE-REFRESH_TOKEN_EXPIRY', exp);
  }
};

export const clearJwtData = () => {
  localStorage.setItem('BOOKING-PAGE-ACCESS_TOKEN', '');
  localStorage.setItem('BOOKING-PAGE-ACCESS_TOKEN_EXPIRY', '');
  localStorage.setItem('BOOKING-PAGE-REFRESH_TOKEN', '');
  localStorage.setItem('BOOKING-PAGE-REFRESH_TOKEN_EXPIRY', '');
};

export class AuthService extends BookingBaseService {
  get = (url, params, options) => {
    return axios.get(url, { params, ...this.createAuthRequestConfig(options) });
  };
  post = (url, body, options) => {
    return axios.post(url, body, this.createAuthRequestConfig(options));
  };
  put = (url, body, options) => {
    return axios.put(url, body, this.createAuthRequestConfig(options));
  };
  delete = (url, options) => {
    return axios.delete(url, this.createAuthRequestConfig(options));
  };

  createAuthRequestConfig = (options) => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    };

    const slug = localStorage.getItem('BOOKING-PAGE-COMPANY_SLUG');

    if (slug) {
      headers['company_slug'] = slug;
    }

    if (options) {
      const { authorization, senderOrganisationSlug, noSlug } = options;
      if (authorization) {
        headers['Authorization'] = authorization;
      }
      if (senderOrganisationSlug) {
        headers['sender_organisation_slug'] = senderOrganisationSlug;
      }
      if (noSlug && headers['company_slug']) {
        delete headers['company_slug'];
      }
    }
    const config = { headers, cancelToken: options?.axiosCancelToken };
    addVersionHeader(config);
    return config;
  };
}

export const authService = new AuthService();
