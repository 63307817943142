import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { GetReducerState, RouterReducer } from '@yojee/types';
import addressPickerReducer from '@yojee/ui/address-picker/reducers/reducer';
import { featuresReducer } from '@yojee/ui/feature-management/reducers/reducer';
import { OrderBookingReducer } from '@yojee/ui/new-order-booking/reducers/reducer';

import companyReducer from './company';
import landingReducer from './landing';
import loginReducer from './login';
import v4BatchOrderReducer from './v4BatchOrder';

const loginPersistConfig = {
  key: 'login',
  storage,
  blacklist: ['componentState', 'loading', 'error'],
};

const reducers = (routerReducer: RouterReducer) => ({
  router: routerReducer,
  login: persistReducer(loginPersistConfig, loginReducer),
  company: companyReducer,
  landing: landingReducer,
  v4BatchOrder: v4BatchOrderReducer,
  addressPicker: addressPickerReducer,
  features: featuresReducer,
  ...OrderBookingReducer,
});

type Reducers = ReturnType<typeof reducers>;

export type BookingState = {
  [k in keyof Reducers]: GetReducerState<Reducers[k]>;
};

export default reducers;
