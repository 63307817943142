import '../../styles/components/Login/sessionExpiredDialog.scss';

import Dialog from '@material-ui/core/Dialog';
import React, { memo } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { hideSessionExpiredDialog } from '../../sagas/login/loginActions';

function SessionExpiredDialog(props) {
  const dispatch = useDispatch();
  const isShowSessionExpiredDialog = useSelector((state) => state.login.componentState.isShowSessionExpiredDialog);

  const closeDialog = () => {
    dispatch(hideSessionExpiredDialog());
  };

  return (
    <Dialog open={isShowSessionExpiredDialog} className="session-expired-dialog-container" maxWidth="md">
      <div className="body">
        <Trans>Your session has expired. Please log in again</Trans>
      </div>
      <button onClick={closeDialog} className="btn btn-primary">
        <Trans>OK</Trans>
      </button>
    </Dialog>
  );
}

export default memo(SessionExpiredDialog);
