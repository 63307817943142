import '../../styles/components/Login/resetPasswordDialog.scss';

import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';

import * as loginActions from '../../sagas/login/loginActions';
import {
  isIncludeNumber,
  isIncludeSpecialChars,
  isLowerCase,
  isUpperCase,
  isValidEmail,
  isValidPassword,
} from '../../utils/validators';
import withRouter from '../Order/Sub/withRouter';

class ResetPasswordDialog extends Component {
  constructor(props) {
    super(props);

    const searchParams = this.props.router.searchParams;
    const resetPasswordToken = searchParams.get('reset_password_token');

    let mode = 'sendResetEmail';
    if (resetPasswordToken) {
      mode = 'resetPassword';
    }

    this.state = {
      resetPasswordToken: resetPasswordToken,
      mode: mode,

      resetPasswordForm: {
        resetPassword1: '',
        resetPassword2: '',
        validators: {
          resetPassword1: ['resetPassword1'],
          resetPassword2: ['resetPassword2'],
        },
        errors: {
          resetPassword1: [],
          resetPassword2: [],
        },
        touched: {
          resetPassword1: false,
          resetPassword2: false,
        },
      },
      sendResetEmailForm: {
        email: '',
        validators: {
          email: ['email'],
        },
        errors: {
          email: [],
        },
        touched: {
          email: false,
        },
      },
    };
  }

  convertMessageToDiv = (message, isFromBE) => {
    const renderDiv = (item, index) => (
      <div className="my-translated-paragraph" key={index}>
        {item}
      </div>
    );
    if (isFromBE) {
      const msg = message.split(',');
      return msg.map((item, index) => renderDiv(item, index));
    } else {
      return message.map((item, index) => renderDiv(item, index));
    }
  };

  renderError = () => {
    const { error } = this.props;
    if (error && error.failed) {
      return (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={error.failed}
          autoHideDuration={4000}
          onClose={this.props.resetErrors}
          message={<div>{this.convertMessageToDiv(error.message, error.failed)}</div>}
        />
      );
    }
  };

  onFieldChange = (form, field) => (event) => {
    const {
      resetPasswordForm: { resetPassword2 },
    } = this.state;
    const _target = event.target;
    const value = _target.type === 'checkbox' ? _target.checked : _target.value;
    this.setState(
      {
        [form]: {
          ...this.state[form],
          [field]: value,
        },
      },
      () => {
        this.validateField(form, field, value);
        if (field === 'resetPassword1') {
          this.validateField(form, 'resetPassword2', resetPassword2);
        }
      }
    );
  };

  onFieldBlur = (form, field) => (event) => {
    this.setState({
      [form]: {
        ...this.state[form],
        touched: {
          ...this.state[form].touched,
          [field]: true,
        },
      },
    });
  };

  validateField = (form, field, value) => {
    const {
      resetPasswordForm: { resetPassword1 },
    } = this.state;
    if (this.state[form].validators[field]) {
      const errors = [];
      this.state[form].validators[field].forEach((validator) => {
        switch (validator) {
          case 'email':
            if (!isValidEmail(value)) {
              errors.push(<Trans i18nKey="reset-password-invalid-email">Invalid Email</Trans>);
            }
            break;
          case 'resetPassword1':
            if (!value) {
              errors.push(<Trans key={`${field}-error-validator`} i18nKey="Field is required" />);
            }

            if (!isValidPassword(value)) {
              errors.push(<Trans key={`${field}-error-validator`} i18nKey="password-length-require" />);
            }

            if (!isLowerCase(value)) {
              errors.push(<Trans key={`${field}-error-validator`} i18nKey="invalid-lower-case" />);
            }

            if (!isUpperCase(value)) {
              errors.push(<Trans key={`${field}-error-validator`} i18nKey="invalid-upper-case" />);
            }

            if (!isIncludeNumber(value)) {
              errors.push(<Trans key={`${field}-error-validator`} i18nKey="invalid-include-number" />);
            }

            if (!isIncludeSpecialChars(value)) {
              errors.push(<Trans key={`${field}-error-validator`} i18nKey="invalid-special-chars" />);
            }

            break;
          case 'resetPassword2':
            if (!value) {
              errors.push(<Trans>Required</Trans>);
            }

            if (resetPassword1 !== value) {
              errors.push(<Trans i18nKey="reset-invalid-password2">The entered passwords did not match</Trans>);
            }
            break;
        }
      });

      this.setState((prevState) => {
        return {
          [form]: {
            ...prevState[form],
            errors: {
              ...prevState[form].errors,
              [field]: errors,
            },
          },
        };
      });
    }
  };

  renderSendResetEmail = () => {
    const { errors, touched, email } = this.state.sendResetEmailForm;
    const { resetPasswordEmailSent, loading } = this.props;
    let _content = '';
    if (resetPasswordEmailSent) {
      _content = (
        <div className="send-reset-email-container">
          <div className="email-sent-img" />
          <div className="email-sent-text">
            <Trans i18nKey="passwordResetEmailSent" />.
          </div>
          <button onClick={this.closeDialog} className="email-sent-button btn btn-primary">
            <Trans>Done</Trans>
          </button>
        </div>
      );
    } else {
      _content = (
        <div className="send-reset-email-container" id="sendResetEmail">
          <h2>
            <Trans>Reset Password</Trans>
          </h2>
          <p className="info">
            <Trans>Enter your email to reset password</Trans>.
          </p>
          <form onSubmit={this.sendResetPasswordEmail}>
            <div className={'form-group' + (touched.email && errors.email.length > 0 ? ' has-error' : '')}>
              <label htmlFor="forgot-password-email">
                <Trans>Registered email address</Trans>*
              </label>
              <div className="form-control">
                <input
                  className="field"
                  type="email"
                  id="forgot-password-email"
                  onBlur={this.onFieldBlur('sendResetEmailForm', 'email')}
                  onChange={this.onFieldChange('sendResetEmailForm', 'email')}
                />
              </div>
              {touched.email && errors.email.length > 0 ? <p className="error-message">{errors.email}</p> : null}
            </div>
            <div className="button-container">
              <button type="button" className="btn btn-default" onClick={this.closeDialog}>
                <Trans>Cancel</Trans>
              </button>
              <button
                type="submit"
                className={'btn btn-primary' + (loading.sendPasswordResetEmail ? ' loading' : '')}
                disabled={!email || errors.email.length || loading.sendPasswordResetEmail}
              >
                <Trans>Submit</Trans>
              </button>
            </div>
          </form>
          <span className="close" onClick={this.closeDialog} />
        </div>
      );
    }
    return _content;
  };

  renderResetPassword = () => {
    const { errors, touched } = this.state.resetPasswordForm;
    const { passwordUpdated, loading } = this.props;

    const anyError = Object.entries(errors)
      .map(([k, v]) => v)
      .some((field) => field.length);
    const dirty = touched.resetPassword1 && touched.resetPassword2;
    const enable = !anyError && dirty;

    if (passwordUpdated) {
      return (
        <div className="reset-password-container">
          <div className="password-updated-img" />
          <div className="password-updated-text">
            <Trans>Your password has been reset successfully</Trans>.
          </div>
          <button onClick={this.props.showLoginDialog} className="password-updated-button btn btn-primary">
            <Trans>Log in</Trans>
          </button>
        </div>
      );
    } else {
      return (
        <div className="reset-password-container" id="resetPassword">
          <h2>
            <Trans>New password</Trans>
          </h2>
          <p className="info">
            <Trans>Create a new password below</Trans>.
          </p>
          <form onSubmit={this.resetPassword}>
            <div
              className={
                'form-group' + (touched.resetPassword1 && errors.resetPassword1.length > 0 ? ' has-error' : '')
              }
            >
              <label id="resetPasswordLabel1">
                <Trans>New Password</Trans> *
              </label>
              <div className="form-control">
                <input
                  type="password"
                  className="field"
                  id="resetPassword1"
                  onBlur={this.onFieldBlur('resetPasswordForm', 'resetPassword1')}
                  onChange={this.onFieldChange('resetPasswordForm', 'resetPassword1')}
                />
              </div>
              {touched.resetPassword1 && errors.resetPassword1.length > 0 ? (
                <div className="error-message">{this.convertMessageToDiv(errors.resetPassword1)}</div>
              ) : null}
            </div>

            <div
              className={
                'form-group' + (touched.resetPassword2 && errors.resetPassword2.length > 0 ? ' has-error' : '')
              }
            >
              <label id="resetPasswordLabel2">
                <Trans>Re-enter new password</Trans> *
              </label>
              <div className="form-control">
                <input
                  type="password"
                  className="field"
                  id="resetPassword2"
                  onBlur={this.onFieldBlur('resetPasswordForm', 'resetPassword2')}
                  onChange={this.onFieldChange('resetPasswordForm', 'resetPassword2')}
                />
              </div>
              {touched.resetPassword2 && errors.resetPassword2.length > 0 ? (
                <div className="error-message">{this.convertMessageToDiv(errors.resetPassword2)}</div>
              ) : null}
            </div>

            <div className="button-container">
              <button type="button" className="btn btn-default" onClick={this.closeDialog}>
                <Trans>Cancel</Trans>
              </button>
              <button
                type="submit"
                className={'btn btn-primary' + (loading.resetPassword ? ' loading' : '')}
                disabled={!enable}
              >
                <Trans>Submit</Trans>
              </button>
            </div>
          </form>
          <span className="close" onClick={this.closeDialog} />
        </div>
      );
    }
  };

  render() {
    const { mode } = this.state;
    return (
      <Dialog
        open={this.props.isShowResetPasswordDialog}
        className="reset-password-dialog-container"
        maxWidth={false}
        scroll="body"
      >
        <div className="body">
          {this.renderError()}
          {mode === 'sendResetEmail' && this.renderSendResetEmail()}
          {mode === 'resetPassword' && this.renderResetPassword()}
        </div>
      </Dialog>
    );
  }

  closeDialog = () => {
    this.props.hideResetPasswordDialog();
  };

  sendResetPasswordEmail = (e) => {
    e.preventDefault();
    const { email } = this.state.sendResetEmailForm;
    this.props.sendPasswordResetEmail({ email });
  };

  resetPassword = (e) => {
    e.preventDefault();
    if (!this.state.resetPasswordToken) {
      console.log('missing token to reset password');
      return;
    }
    const {
      resetPasswordToken,
      resetPasswordForm: { resetPassword1, resetPassword2 },
    } = this.state;
    if (resetPassword1 !== resetPassword2) {
      return;
    }

    this.props.updatePassword({
      reset_password_token: resetPasswordToken,
      password: resetPassword1,
    });
  };
}

const mapStateToProps = (state) => {
  return {
    resetPasswordEmailSent: state.login.componentState.resetPasswordEmailSent,
    passwordUpdated: state.login.componentState.passwordUpdated,
    error: state.login.error,
    loading: state.login.loading,
    loadingCompanyInfo: state.login.loading,
    isShowResetPasswordDialog: state.login.componentState.isShowResetPasswordDialog,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetErrors: () => dispatch(loginActions.resetErrors()),
    sendPasswordResetEmail: (payload) => dispatch(loginActions.sendPasswordResetEmail(payload)),
    updatePassword: (payload) => dispatch(loginActions.updatePassword(payload)),
    showLoginDialog: () => dispatch(loginActions.showLoginDialog()),
    hideResetPasswordDialog: () => dispatch(loginActions.hideResetPasswordDialog()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetPasswordDialog));
