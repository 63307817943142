const defaultState = {
  loading: {
    getItemTypes: false,
    getServiceTypes: false,
    getNonoperatingZones: false,
    getStripeApiKey: false,
  },
  companyInfo: {
    timezone: null,
    branding: {
      pricingUrl: '',
      adminPrimaryColor: '',
      backgroundColor: '',
      style: {},
      'header-primary-button': {
        backgroundColor: '#2c677e',
        borderColor: '#2c677e',
        color: '#ffffff',
      },
      'header-secondary-button': {
        backgroundColor: '#2c677e',
        borderColor: '#ffffff',
        color: '#ffffff',
      },
      banner: undefined,
    },
    currency: '',
    country: '',
    deliveryOptions: {
      minWeight: 1.0,
      maxWeight: 30,
      startHour: null,
      endHour: null,
    },
    features: {
      multileg: undefined,
      address_book: undefined,
      cod_price: undefined,
      apply_hub_operations: undefined,
    },
    sender: {
      language: 'en',
      requiresPayment: {},
      orderPriceSource: null,
    },
    useBookingPageOnlyForReports: undefined,
    copy: {},
  },
  error: {
    failed: false,
    message: null,
  },
  stripeApiKey: null,
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  GET_STRIPE_API_KEY_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      loading: { ...state.loading, getStripeApiKey: false },
      stripeApiKey: sagaData.stripeApiKey,
    };
  },
  GET_STRIPE_API_KEY_FAILED: (state, sagaData) => {
    const { error } = sagaData;
    return {
      ...state,
      loading: { ...state.loading, getStripeApiKey: false },
      error: {
        failed: true,
        message: error?.message ?? 'Failed to get stripe info',
      },
    };
  },

  START_LOADING: (state, sagaData) => {
    return {
      ...state,
      loading: { ...state.loading, [sagaData.loadingAction]: true },
    };
  },
  GET_COMPANY_INFO_SUCCESSFUL: (state, sagaData) => {
    //  filter out branding data (stored in a separate object)
    const data = Object.keys(sagaData.companyData)
      .filter((key) => key !== 'branding')
      .reduce((obj, key) => {
        obj[key] = sagaData.companyData[key];
        return obj;
      }, {});
    return {
      ...state,
      loading: { ...state.loading, getCompanyInfo: false },
      companyInfo: { ...state.companyInfo, ...data },
    };
  },
  GET_COMPANY_INFO_FAILED: (state, sagaData) => {
    const { error } = sagaData;
    return {
      ...state,
      loading: { ...state.loading, getCompanyInfo: false },
      error: {
        failed: true,
        message: error?.message ?? 'Failed to get company info',
      },
    };
  },
  SET_COMPANY_BRANDING: (state, sagaData) => {
    return {
      ...state,
      companyInfo: { ...state.companyInfo, branding: { ...state.companyInfo.branding, ...sagaData.branding } },
    };
  },
  GET_RESET_ERRORS: (state) => {
    return {
      ...state,
      error: { ...defaultState.error },
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
