import { Box, Button, styled, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { orderApiV4 } from '@yojee/api/v4/orderApi';
import { templateApiV4 } from '@yojee/api/v4/templateApi';
import useService from '@yojee/helpers/hooks/useService';
import { BinaryActionDialog, Select } from '@yojee/ui/common';

import { v4ResetBatchUploadData, v4UploadBatchFile } from '../../../sagas/v4BatchOrder/batchOrderActions';
import Zone from './Zone';

const StyledContainer = styled(Box)({
  paddingTop: 24,
});

const StyledSecondaryTypography = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  color: theme.color.grey02,
}));

const StyledSecondaryButton = styled(Button)(({ theme }) => ({
  marginLeft: 16,
  height: 32,
  borderRadius: 6,

  textTransform: 'none',
  fontWeight: 600,
  fontSize: 14,
  color: theme.palette.text.primary,
  backgroundColor: 'rgba(4, 9, 33, 0.04)',

  '&:hover': {
    backgroundColor: 'rgba(4, 9, 33, 0.08)',
  },
}));

const BatchOrderDialog = ({ open, onClose }) => {
  const slug = useSelector((state) => state.login.accountInfo?.slug);
  const senderId = useSelector((state) => state.login.accountInfo?.id);
  const batchState = useSelector((state) => state.v4BatchOrder.state);
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [selectedTemplateId, setSelectedTemplateId] = useState(undefined);
  const [uploadedFile, setUploadedFile] = useState(undefined);

  const { execute: getSelectedTemplateDetail, data: selectedTemplateDetail } = useService(
    templateApiV4.getPublicCompanyTemplateDetail,
    {
      lazy: true,
    }
  );

  const { data: templates } = useService(templateApiV4.getPublicCompanyActivatedTemplates);
  const templateOptions = useMemo(
    () =>
      templates?.map((t) => ({
        value: t.id,
        label: t.template_type_name,
        default: !!t.default_at,
      })) ?? [],
    [templates]
  );

  useEffect(() => {
    return () => {
      dispatch(v4ResetBatchUploadData());
    };
  }, []);

  useEffect(() => {
    if (!templateOptions?.length) {
      return;
    }
    setSelectedTemplateId(templateOptions.find((t) => t.default)?.value);
  }, [templateOptions]);

  useEffect(() => {
    if (selectedTemplateId !== undefined) {
      getSelectedTemplateDetail(selectedTemplateId);
    }
  }, [selectedTemplateId]);

  const isDownloadTemplateDisabled = selectedTemplateId === undefined;
  const isActionDisabled = isDownloadTemplateDisabled || batchState !== 'not_submitted' || !uploadedFile;

  const handleTemplateChange = (e) => {
    setSelectedTemplateId(e.target.value);
  };

  const handleDownloadTemplate = (format) => {
    if (selectedTemplateId === undefined) {
      return;
    }

    const url = orderApiV4.getBatchUploadSampleUrl({
      format,
      slug,
      templateId: selectedTemplateId,
      userType: 'sender',
    });

    window.open(url, '_self');
  };

  const handleUpload = () => {
    if (selectedTemplateId === undefined || uploadedFile === undefined || !senderId) {
      return;
    }

    const body = {
      templateId: selectedTemplateId,
      file: uploadedFile,
      format: uploadedFile.type === 'text/csv' ? 'CSV' : 'XLSX',
      uploaderId: senderId,
    };
    dispatch(v4UploadBatchFile(body));
  };

  return (
    <BinaryActionDialog
      open={open}
      onClose={onClose}
      title={<Trans>Batch upload</Trans>}
      primaryAction={{
        label: t('Save'),
        onClick: handleUpload,
        disabled: isActionDisabled,
        hidden: batchState === 'completed',
      }}
      secondaryAction={{
        label: batchState !== 'completed' ? t('Cancel') : t('Close'),
        onClick: onClose,
      }}
      showTitleDivider
    >
      <StyledContainer>
        <Box mb={2} width="50%">
          <Select
            name="template"
            value={selectedTemplateId}
            options={templateOptions}
            label={<Trans>Template</Trans>}
            labelId="template-label"
            onChange={handleTemplateChange}
            size="small"
            key={selectedTemplateId}
          />
        </Box>

        <Box mb={2}>
          <Zone
            selectedTemplateDetail={selectedTemplateDetail}
            uploadedFile={uploadedFile}
            setUploadedFile={setUploadedFile}
          />
        </Box>

        <Box display="flex" alignItems="center">
          <StyledSecondaryTypography>{t('Download template')}</StyledSecondaryTypography>

          <StyledSecondaryButton
            disabled={isDownloadTemplateDisabled}
            onClick={() => handleDownloadTemplate('xlsx')}
            variant="contained"
            disableElevation
          >
            {t('Excel')}
          </StyledSecondaryButton>

          <StyledSecondaryButton
            disabled={isDownloadTemplateDisabled}
            onClick={() => handleDownloadTemplate('csv')}
            variant="contained"
            disableElevation
          >
            {t('CSV')}
          </StyledSecondaryButton>
        </Box>
      </StyledContainer>
    </BinaryActionDialog>
  );
};

export default BatchOrderDialog;
