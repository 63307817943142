import moment from 'moment';
import { Trans } from 'react-i18next';

export const TIMEZONE_HEAD_CELLS = [
  { id: 'countryCode', align: 'left', disablePadding: true, label: <Trans>Country Code</Trans> },
  { id: 'timezoneName', align: 'left', disablePadding: false, label: <Trans>TimeZone Name</Trans> },
  { id: 'utcOffset', align: 'right', disablePadding: false, label: <Trans>UTC Offset hh:mm</Trans> },
  { id: 'utcDstOffset', align: 'right', disablePadding: false, label: <Trans>UTC Dst Offset hh:mm</Trans> },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getTimeZoneNames() {
  const zones = new Set();
  const countries = moment.tz.countries();

  for (const country of countries) {
    moment.tz.zonesForCountry(country).reduce((set, zone) => set.add(zone), zones);
  }

  return [...zones].sort();
}

function getOffsetDelta(tz) {
  var z = moment.tz.zone(tz);
  var i = z._index(moment());
  return i < z.offsets.length - 1 ? z.offsets[i] - z.offsets[i + 1] : 0;
}

function getTimeZoneOffset(tz) {
  const dstOffset = moment.tz(tz).utcOffset();
  const offsetDelta = getOffsetDelta(tz);
  const offset = dstOffset + offsetDelta;

  const utcOffset = moment().utcOffset(offset).format('Z');
  const utcDstOffset = moment().utcOffset(dstOffset).format('Z');

  return {
    utcOffset,
    utcDstOffset,
  };
}

export const getTimeZoneLists = () => {
  const timezoneNames = getTimeZoneNames();
  const countryCodes = Object.values(moment.tz._countries);

  return timezoneNames.map((timezoneName) => {
    const { utcOffset, utcDstOffset } = getTimeZoneOffset(timezoneName);
    const countryCode = countryCodes.find((code) => code.zones.includes(timezoneName))?.name;

    return {
      countryCode,
      timezoneName,
      utcOffset,
      utcDstOffset,
    };
  });
};

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};
