import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import * as loginActions from '../../sagas/login/loginActions';
import LoginDialog from '../Login/LoginDialog';
import ResetPasswordDialog from '../Login/ResetPasswordDialog';
import SessionExpiredDialog from '../Login/SessionExpiredDialog';
import Footer from './Footer';
import Header from './Header';
import Loading from './Loading';

const Layout = (props) => {
  const [params] = useSearchParams();
  const resetPasswordToken = params.get('reset_password_token');

  useEffect(() => {
    if (resetPasswordToken) {
      props.showResetPasswordDialog();
    } else {
      props.hideResetPasswordDialog();
    }
  }, [resetPasswordToken]);

  const { loadingSenderInfo, loadingCompanyInfo, loading, children, className, branding, contentClass } = props;
  const isLoading = loadingSenderInfo || loadingCompanyInfo || loading;

  return isLoading ? (
    <Loading />
  ) : (
    <div className={'main-container' + (className ? ` ${className}` : '')}>
      <Header />
      {branding.banner ? (
        <div className="banner-container" style={{ backgroundImage: `url(${branding.banner})` }} />
      ) : null}
      <div className={`content-container ${contentClass ?? ''}`}>{children}</div>
      <LoginDialog />
      <ResetPasswordDialog />
      <SessionExpiredDialog />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dialogStates: state.login.componentState,
    branding: state.company.companyInfo.branding,
    loadingCompanyInfo: state.company.loading.getCompanyInfo,
    loadingSenderInfo: state.login.loading.getSenderInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideResetPasswordDialog: () => dispatch(loginActions.hideResetPasswordDialog()),
    showResetPasswordDialog: () => dispatch(loginActions.showResetPasswordDialog()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
