import { styled } from '@mui/material/styles';

const CheckboxIcon = styled('span')(({ theme }) => ({
  borderRadius: 2,
  width: theme.spacing(2),
  height: theme.spacing(2),
  boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#f5f8fa',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    backgroundColor: 'rgba(4, 9, 33, 0.32)',
  },
}));

export default CheckboxIcon;
