import { IconButton, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import copy from 'copy-to-clipboard';
import React, { useState } from 'react';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  copyIcon: {
    fontSize: 13,
  },
  iconButton: {
    marginLeft: 'auto',
  },
};

const CopyToClipBoardBtn = (props) => {
  const { content, classes } = props;
  const [toolTipTitle, setToolTipTitle] = useState('Copy');

  const _onCopy = () => {
    copy(content);
    setToolTipTitle('Copied');
  };

  return (
    <div className={classes.container}>
      <span>{content}</span>
      <Tooltip title={toolTipTitle} placement="top">
        <IconButton onClick={_onCopy} onMouseEnter={() => setToolTipTitle('Copy')} className={classes.iconButton}>
          <FileCopyOutlinedIcon className={classes.copyIcon} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default withStyles(styles)(CopyToClipBoardBtn);
