import { all, fork, select, takeEvery } from 'redux-saga/effects';

import getEnv from '@yojee/helpers/env/getEnv';
import addressPicker from '@yojee/ui/address-picker/saga/saga';
import featureManagementSagas from '@yojee/ui/feature-management/saga/saga';
import { OrderBookingSagas } from '@yojee/ui/new-order-booking/saga/saga';

import company from './company/companySaga';
import landing from './Landing/landingSaga';
import login from './login/loginSaga';
import v4BatchOrder from './v4BatchOrder/batchOrderSaga';

function* watchAndLog() {
  if (getEnv('REACT_APP_IS_LOCAL') === 'true' && getEnv('REACT_OUTPUT_STATE_CHANGES')) {
    yield takeEvery('*', function* logger(action) {
      const state = yield select();
      console.log('action', action);
      console.log('state after', state);
    });
  }
}

export default function* root() {
  yield all([
    fork(watchAndLog),
    fork(login),
    fork(landing),
    fork(company),
    fork(OrderBookingSagas),
    fork(v4BatchOrder),
    fork(addressPicker),
    fork(featureManagementSagas),
  ]);
}
