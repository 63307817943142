import {
  Checkbox,
  InputBase,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select as MuiSelect,
  SelectProps,
  styled,
} from '@mui/material';
import { t } from 'i18next';
import React, { ReactNode } from 'react';

import { CheckboxCheckedIcon, CheckboxIcon } from '@yojee/ui/common/icons';

type Props = SelectProps & {
  options: { label: string; value: string | number }[];
  showSelectedCount?: boolean;
  labelStyle?: React.CSSProperties;
  labelRenderer?: React.FC;
  inputStyle?: 'no-outlined';
};

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.color.grey02,
  transform: 'translate(0, -0.5px) scale(0.75)',
}));

const StyledMuiSelect = styled(MuiSelect)(({ theme }) => ({
  ...theme.typography.body2,
  height: theme.spacing(5),
  marginTop: -2,
  '& fieldset': {
    top: 0,
  },
  '& fieldset legend': {
    display: 'none',
  },
  '&.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: 1,
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.divider,
  },
}));

const NoOutlinedInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 4,
    fontSize: 14,
    position: 'relative',
    minWidth: 'max-content !important',
    color: theme.palette.primary.main,
    marginLeft: 8,
    marginTop: 4,
  },
  '& .MuiInputBase-input:focus': {
    borderRadius: 4,
  },
  '& .MuiSelect-icon': {
    color: theme.palette.primary.main,
    width: 18,
    height: 18,
    top: 'calc(50% - 0.35em)',
  },
}));

const Select: React.FC<Props> = ({
  options,
  showSelectedCount,
  labelStyle,
  labelRenderer: LabelRenderer,
  inputStyle,
  ...selectProps
}) => {
  const renderValue = (selected: unknown): ReactNode => {
    const selectedValues = selected as string | string[] | number | number[];

    if (
      selectedValues === undefined ||
      selectedValues === '' ||
      (Array.isArray(selectedValues) && selectedValues.length === 0)
    ) {
      return selectProps.placeholder || t('Select ...');
    }

    if (selectProps.multiple) {
      if (showSelectedCount) {
        return `${(selectedValues as string[]).length} selected`;
      }

      const selectedLabels = (selectedValues as string[]).map((value) => {
        const option = options.find((option) => option.value === value);
        return option?.label;
      });

      return selectedLabels.join(', ');
    }

    return options.find((option) => option.value === selected)?.label;
  };

  const renderLabel = () => {
    if (LabelRenderer) return <LabelRenderer />;
    return (
      selectProps.label && (
        <StyledInputLabel id={selectProps.labelId} shrink sx={labelStyle}>
          {selectProps.label}
        </StyledInputLabel>
      )
    );
  };

  return (
    <>
      {renderLabel()}
      <StyledMuiSelect
        input={!inputStyle ? <OutlinedInput fullWidth /> : <NoOutlinedInput />}
        renderValue={renderValue}
        displayEmpty
        MenuProps={{
          autoFocus: false,
        }}
        {...selectProps}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={(theme) => ({
              ...theme.typography.body2,
            })}
          >
            {selectProps.multiple ? (
              <>
                <Checkbox
                  icon={<CheckboxIcon />}
                  checkedIcon={<CheckboxCheckedIcon />}
                  checked={(selectProps.value as (string | number)[])?.includes(option.value as string | number)}
                  size="small"
                  sx={{
                    padding: '4px',
                    marginRight: 1,
                  }}
                />
                <ListItemText primary={option.label} primaryTypographyProps={{ fontSize: 'inherit' }} />
              </>
            ) : (
              option.label
            )}
          </MenuItem>
        ))}
      </StyledMuiSelect>
    </>
  );
};

export default Select;
