import './CustomDialog.scss';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

const BackdropProps = { invisible: true };

const StyledDialogActions = withStyles({
  root: {
    padding: '8px 24px',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
})(DialogActions);

const StyledDialogTitle = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
})(DialogTitle);

const CustomDialog = ({ children, title, subTitle, actions, open, onClose, className = '' }) => {
  const onCloseClick = (event, reason) => {
    if (reason !== 'backdropClick') {
      onClose(event, reason);
    }
  };

  return (
    <Dialog
      onClose={onCloseClick}
      aria-labelledby="simple-dialog-title"
      BackdropProps={BackdropProps}
      open={open}
      className={`custom-dialog ${className}`}
    >
      {title ? (
        <StyledDialogTitle id="simple-dialog-title" data-cy="dialog-title" onClose={onClose}>
          <div className="custom-dialog-title">{title}</div>
          {subTitle}
        </StyledDialogTitle>
      ) : undefined}

      <DialogContent className="custom-dialog-content" data-cy="dialog-content">
        {children}
      </DialogContent>

      {actions && <StyledDialogActions className="custom-dialog-actions">{actions}</StyledDialogActions>}

      {onClose ? (
        <IconButton data-cy="close" aria-label="Close" size="small" className="dialog-close-btn" onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : undefined}
    </Dialog>
  );
};

export default CustomDialog;
