export const loginByEmail = (payload) => {
  return { type: 'REQUEST_LOGIN_BY_EMAIL', payload: payload };
};

export const getOtpCode = (payload) => {
  return { type: 'REQUEST_GET_OTP_CODE', payload: payload };
};

export const loginByPhoneNumber = (payload) => {
  return { type: 'REQUEST_LOGIN_BY_PHONE_NUMBER', payload: payload };
};

export const getSenderInfo = () => {
  return { type: 'REQUEST_GET_SENDER_INFO' };
};

export const resetErrors = () => {
  return { type: 'RESET_ERRORS' };
};

export const signOut = () => {
  return { type: 'SIGN_OUT' };
};

export const sendPasswordResetEmail = (payload) => {
  return { type: 'REQUEST_SEND_PASSWORD_RESET_EMAIL', payload };
};

export const updatePassword = (payload) => {
  return { type: 'REQUEST_UPDATE_PASSWORD', payload };
};

export const showLoginDialog = (payload) => {
  return { type: 'REQUEST_SHOW_LOGIN_DIALOG', payload };
};

export const hideLoginDialog = () => {
  return { type: 'REQUEST_HIDE_LOGIN_DIALOG' };
};

export const showResetPasswordDialog = () => {
  return { type: 'REQUEST_SHOW_RESET_PASSWORD_DIALOG' };
};

export const hideResetPasswordDialog = () => {
  return { type: 'REQUEST_HIDE_RESET_PASSWORD_DIALOG' };
};

export const showSessionExpiredDialog = () => {
  return { type: 'SHOW_SESSION_EXPIRED_DIALOG' };
};

export const hideSessionExpiredDialog = () => {
  return { type: 'HIDE_SESSION_EXPIRED_DIALOG' };
};
