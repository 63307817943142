import { BookingBaseService } from '@yojee/api/baseService/bookingBaseService';
import { DEFAULT_TASK_TYPE_SEQUENCE_SINGLE_LEG, SENDER_TYPES } from '@yojee/helpers/constants';

import { flattenArray, isObjectEmpty, parseBoolean, parseNumber, parseString } from '../utils';
import { getCountryCodeByName } from '../utils/countryCode';
import { authService } from './authService';

export class CompanyService extends BookingBaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
    // eslint-disable-next-line no-undef
    if (typeof google !== 'undefined') {
      // eslint-disable-next-line no-undef
      this.googlePlacesAutoCompleteService = new google.maps.places.AutocompleteService();
    }
  }

  getGooglePlaceAutoComplete = (input, country) => {
    return new Promise((resolve, reject) => {
      const countryCode = getCountryCodeByName(country);

      const requestParam = {
        input,
        componentRestrictions: {
          country: countryCode,
        },
      };
      this.googlePlacesAutoCompleteService.getPlacePredictions(requestParam, (results, status) => {
        // eslint-disable-next-line no-undef
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(results.map((address) => ({ address1: address.description })));
        } else {
          resolve([]);
        }
      });
    });
  };

  getAddressSuggestionFromGoogleAndAddressBook = (input, addressBookEnabled, operationCountry) => {
    const addressBookPayload = { type: 'Pickup', query: input };
    const promises = [this.getGooglePlaceAutoComplete(input, operationCountry)];
    if (addressBookEnabled) {
      promises.push(this.getCompanyAddressBookEntries(addressBookPayload).catch(() => []));
    }
    return Promise.all(promises)
      .then((ww) => {
        return {
          addressBook: ww[1]?.data ?? [],
          googleMap: ww[0],
        };
      })
      .catch(() => ({}));
  };

  getCompanyAddressBookEntries = (payload = null) => {
    const requestPayload = this.transformDataRequestAddressBookEntries(payload);
    return this.authService
      .get(this.getUmbrellaApiUrl('sender/address_items'), requestPayload)
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };

  getCompanyItemTypes = (slug) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('public/orders/item_types'), {
        company_slug: slug,
      })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? this._transformCompanyItemTypesData(result['data']) : null;
      })
      .catch((error) => this.handleError(error));
  };

  getCompanyInfo = (slug) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('public/orders/company_info'), {
        company_slug: slug,
      })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? this._transformCompanyInfoData(result['data']) : null;
      })
      .catch((error) => this.handleError(error));
  };

  getCompanyServiceTypes = (slug) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('public/orders/service_types'), {
        company_slug: slug,
      })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data']
          ? this._transformCompanyServiceTypeData(result['data'])
          : {
              serviceTypes: [],
              nonOperatingDates: [],
            };
      })
      .catch((error) => this.handleError(error));
  };

  getCompanyTaskTypes = (slug) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('public/task_type_mappings'), {
        company_slug: slug,
      })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : [];
      })
      .catch((error) => this.handleError(error));
  };

  isServiceTypeMultileg = (serviceTypeObject = null) => {
    if (!serviceTypeObject) {
      return false;
    }
    return serviceTypeObject?.point2point === false;
  };

  getCompanyNonoperatingZones = (slug) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('public/orders/non_operational_zones'), {
        company_slug: slug,
      })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getStripeApiKey = (slug) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('public/payments/stripe_key'), {
        company_slug: slug,
        type: 'charge',
      })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  getCompanyBranding = (data) => {
    if (!data) {
      return null;
    }
    return {
      backgroundColor: parseString(data['background_color']),
      banner: parseString(data['banner']),
      bannerHeader: parseString(data['banner_header']),
      bannerTextColor: parseString(data['banner_text_color']),
      bannerTextLine1: parseString(data['banner_text_line_1']),
      bannerTextLine2: parseString(data['banner_text_line_2']),
      bannerTextLine3: parseString(data['banner_text_line_3']),
      favicon: parseString(data['favicon']),
      generatedFavicon: data['generated_favicon'] ? data['generated_favicon'] : {},
      logo: parseString(data['logo']),
      pricingUrl: parseString(data['pricing_url']),
      textColor: parseString(data['text_color']),
      headerCtaBackgroundColor: parseString(data['header_cta_color']),
      headerCtaTextColor: parseString(data['header_cta_text_color']),
      termsAndConditionsUrl: parseString(data['t_and_c_url']),
      style: this._getCompanyBrandingStyle(data),
    };
  };

  transformDataRequestAddressBookEntries = (data) => {
    if (!data) {
      return {
        page: 1,
        page_size: 5,
      };
    }
    const requestData = {
      page: data.pageNo || 1,
      page_size: data.pageSize || 5,
    };

    if (data.category) {
      requestData['category'] = data.category;
    }
    if (data.senderOrganisationSlug) {
      requestData['sender_organisation_slug'] = data.senderOrganisationSlug;
    }
    if (data.query) {
      requestData['query'] = data.query;
    }

    return requestData;
  };

  _getCompanyBrandingStyle = (brandingData) => {
    if (isObjectEmpty(brandingData)) {
      return null;
    }
    const _backgroundColor = brandingData['background_color'] ? brandingData['background_color'] : null;
    const _headerCtaBackgroundColor = brandingData['header_cta_color'] ? brandingData['header_cta_color'] : null;
    const _headerCtaTextColor = brandingData['header_cta_text_color'] ? brandingData['header_cta_text_color'] : null;
    return {
      'header-background-color': {
        backgroundColor: _backgroundColor,
        borderColor: _headerCtaBackgroundColor,
      },
      'header-text-color': {
        color: _headerCtaBackgroundColor,
      },
      'header-cta-background-color': {
        backgroundColor: _headerCtaBackgroundColor,
      },
      'header-cta-border-color': {
        borderColor: _headerCtaBackgroundColor,
      },
      'header-primary-button': {
        borderColor: _headerCtaBackgroundColor,
        color: _headerCtaTextColor,
      },
      'header-secondary-button': {
        borderColor: _headerCtaTextColor,
        color: _headerCtaTextColor,
      },
    };
  };

  //  if multileg is disabled, filter out all service types that have number of tasks in task_type_sequence
  //  greater or smaller than 2
  filterServiceTypes = ({ isMultilegEnabled, serviceTypes }) => {
    let _filteredServiceTypes = [...serviceTypes];
    if (!isMultilegEnabled && Array.isArray(_filteredServiceTypes) && _filteredServiceTypes.length > 0) {
      _filteredServiceTypes = _filteredServiceTypes.filter(
        (serviceType) =>
          serviceType.taskTypeSequence &&
          Array.isArray(serviceType.taskTypeSequence) &&
          flattenArray(serviceType.taskTypeSequence).length === 2
      );
    }
    return _filteredServiceTypes;
  };

  _transformCompanyItemTypesData = (data) => {
    if (!data || data.length < 1) {
      return null;
    }
    return data.map((itemType) => {
      return {
        name: parseString(itemType['name']),
        id: parseInt(itemType['id']),
      };
    });
  };

  _transformCompanyInfoData = (data) => {
    if (!data) {
      return null;
    }
    const _branding = data['branding'] ? data['branding'] : null;
    const _sender = data['sender'] ? data['sender'] : null;
    const _deliveryOptions = data['delivery_options'] ? data['delivery_options'] : null;
    const _copy = data['copy'] ? data['copy'] : null;
    const _features = data['features'] || null;
    const _companyName = data['company_name'] || null;

    return {
      sender: this._transformCompanySenderData(_sender),
      currency: parseString(data['currency']),
      company_name: _companyName,
      deliveryOptions: this._transformDeliveryOptions(_deliveryOptions),
      copy: this._transformCopyData(_copy),
      template: data['template'] && data['template']['fields_schema'] ? data['template']['fields_schema'] : {},
      newFormatTemplateId: data?.template?.new_format_template_id,
      features: this._transformFeaturesData(_features),
      branding: _branding,
      country: parseString(data['country']),
      timezone: data['timezone'] || null,
      booking_dashboard_provider: data?.['booking_dashboard_provider'],
      useBookingPageOnlyForReports: data?.['sender_access']?.['use_booking_page_only_for_reports'] || false,
      bookingReports: data?.['booking_reports'],
      bookingReportTitle: data?.['booking_report_title'],
    };
  };

  _transformFeaturesData = (data) => {
    if (!data) {
      return null;
    }
    return {
      multileg: parseBoolean(data['multileg']),
      address_book: parseBoolean(data['address_book']),
      cod_price: parseBoolean(data['cod_price']),
      apply_hub_operations: parseBoolean(data['apply_hub_operations']),
      use_rate_engine: parseBoolean(data['use_rate_engine']),
    };
  };

  _transformCopyData = (data) => {
    if (!data) {
      return null;
    }
    return {
      defaultLengthUnit: data['default.unit'] ? parseString(data['default.unit']) : 'cm',
      defaultWeightUnit: data['default.weight_unit'] ? parseString(data['default.weight_unit']) : 'kg',
      externalItemIdTitle: data['items.show.external_customer_id_title']
        ? parseString(data['items.show.external_customer_id_title'])
        : null,
      externalItemId2Title: data['items.show.external_customer_id2_title']
        ? parseString(data['items.show.external_customer_id2_title'])
        : null,
      externalItemId3Title: data['items.show.external_customer_id3_title']
        ? parseString(data['items.show.external_customer_id3_title'])
        : null,
      orderIdTitle: data['items.show.order_id_title'] ? parseString(data['items.show.order_id_title']) : null,
      itemInfoTitle: data['items.show.info_title'] ? parseString(data['items.show.info_title']) : null,
      ccuNameTitle: data['orders.show.ccu_name_title'] ? parseString(data['orders.show.ccu_name_title']) : null,
      externalOrderIdTitle: data['orders.show.external_id_title']
        ? parseString(data['orders.show.external_id_title'])
        : null,
    };
  };

  _transformCompanyServiceTypeData = (data) => {
    if (!data) {
      return [];
    }
    const result = {
      serviceTypes: [],
      nonOperatingDates: data['non_operating_dates'] ? data['non_operating_dates'] : [],
    };
    if (data['service_types'] && data['service_types'].length > 0) {
      data['service_types'].forEach((serviceType) => {
        const _serviceTypeData = serviceType.data ? serviceType.data : serviceType;
        const defaultTaskType = _serviceTypeData['point2point'] ? DEFAULT_TASK_TYPE_SEQUENCE_SINGLE_LEG : null;
        if (_serviceTypeData) {
          result.serviceTypes.push({
            windowSize: parseNumber(_serviceTypeData['window_size']),
            name: parseString(_serviceTypeData['name']),
            minWindowSize: parseNumber(_serviceTypeData['min_window_size']),
            leadTime: parseNumber(_serviceTypeData['lead_time']),
            key: parseString(_serviceTypeData['key']),
            slaTime: parseNumber(_serviceTypeData['sla_time']),
            nonOperatingDays: _serviceTypeData['non_operating_days'] ? _serviceTypeData['non_operating_days'] : [],
            slots: _serviceTypeData['slots'] ? _serviceTypeData['slots'] : [],
            taskTypeSequence: _serviceTypeData['task_type_sequence']
              ? _serviceTypeData['task_type_sequence']
              : defaultTaskType,
            isolateTasks: _serviceTypeData['isolate_tasks'] ? _serviceTypeData['isolate_tasks'] : false,
            lastPickupCutoffTime: _serviceTypeData['slots']
              ? this._getLastPickupCutoffTime(_serviceTypeData['slots'])
              : null,
            pricingModelUuid: _serviceTypeData['pricing_model_uuid'] || null,
            point2point: _serviceTypeData['point2point'] || false,
          });
        }
      });
    }
    return result;
  };

  _getLastPickupCutoffTime = (slots) => {
    if (!slots || slots.length < 1) {
      return null;
    }
    const _lastPickupCutoffTime = slots.reduce((result, slot) => {
      if (
        slot['task_types'] &&
        slot['task_types'].includes('pickup') &&
        slot['cutoff_time'] &&
        slot['cutoff_time'] > result
      ) {
        return slot['cutoff_time'];
      }
      return result;
    }, '');
    return _lastPickupCutoffTime || null;
  };

  _transformCompanySenderData = (data) => {
    if (!data) {
      return null;
    }
    return {
      csHotline: parseString(data['cs_hotline']),
      language: parseString(data['language']),
      orderPriceSource: parseString(data['order_price_source']),
      organisation: parseString(data['organisation']),
      requiresPayment: {
        [SENDER_TYPES.organisation]: parseBoolean(
          data['requires_payment'] && data['requires_payment'][SENDER_TYPES.organisation]
        ),
      },
    };
  };

  _transformDeliveryOptions = (data) => {
    if (!data) {
      return null;
    }
    return {
      endHour: parseInt(data['end_hour']),
      minWeight: Object.hasOwn(data, 'min_weight') ? parseFloat(data['min_weight']) : 1.0,
      startHour: parseInt(data['start_hour']),
    };
  };
}

export const companyService = new CompanyService({ authService });
