import { delay } from '@redux-saga/core/effects';
import { call, put, takeLatest } from 'redux-saga/effects';

import { senderBatchOrderServiceV4 } from '@yojee/api/v4/senderBatchOrderService';

export default function* sagas() {
  yield takeLatest('V4_REQUEST_UPLOAD_BATCH_FILE', uploadBatchFile);
  yield takeLatest('V4_REQUEST_GET_ORDER_BY_BATCH_ID', getOrderByBatchId);
}

function* uploadBatchFile(action) {
  const { file, uploaderId, format, templateId } = action.payload;

  try {
    yield put({ type: 'V4_UPLOAD_BATCH_FILE_START' });
    const data = yield call(senderBatchOrderServiceV4.uploadBatchFile, {
      file,
      uploaderId,
      format,
      templateId,
    });

    const { id: batchId } = data;
    if (batchId) {
      yield put({ type: 'V4_UPDATE_BATCH_UPLOAD_PROGRESS', data: { batchId } });

      let status = 'processing';
      let errors = {};
      while (['processing', 'created'].includes(status)) {
        const data = yield call(senderBatchOrderServiceV4.checkBatchUploadStatus, batchId);
        status = data.status;
        errors = data.errors;
        yield delay(1000);
      }
      if (status === 'completed') {
        yield put({ type: 'V4_UPLOAD_BATCH_FILE_SUCCESSFUL' });
        yield put({ type: 'V4_REQUEST_GET_ORDER_BY_BATCH_ID', payload: batchId });
      } else if (status === 'failed') {
        yield put({ type: 'V4_UPLOAD_BATCH_FILE_FAILED', data: { errors } });
      } else if (status === 'missing_info') {
        yield put({ type: 'V4_UPLOAD_BATCH_FILE_MISSING_INFO', data: { errors } });
      }
    } else {
      yield put({ type: 'V4_UPLOAD_BATCH_FILE_FAILED', data: { errors: 'Internal server error. Please try again' } });
    }
  } catch (e) {
    const errors = e?.response?.data?.errors ?? 'Internal server error. Please try again';
    yield put({ type: 'V4_UPLOAD_BATCH_FILE_FAILED', data: { errors } });
  }
}

function* getOrderByBatchId(action) {
  try {
    const batchId = action.payload;

    const data = yield call(senderBatchOrderServiceV4.getOrdersOfBatchID, batchId);
    yield put({ type: 'V4_GET_ORDER_BY_BATCH_ID_SUCCESSFUL', data: { orders: data } });
  } catch (error) {
    yield put({
      type: 'V4_UPLOAD_BATCH_FILE_FAILED',
      data: {
        errors: 'Internal server error. Please try again',
      },
    });
  }
}
