import { styled, ThemeProvider } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import React from 'react';

import LinearLoading from '@yojee/ui/base/furnitures/LinearLoading';

import Button from '../Button/Button';
import theme from '../theme';

type DialogActionProps = Pick<ButtonProps, 'variant' | 'color' | 'onClick' | 'disabled'> & {
  label: React.ReactNode;
  hidden?: boolean;
};

type Props = Omit<DialogProps, 'title'> & {
  title: React.ReactNode;
  secondaryAction: DialogActionProps;
  primaryAction: DialogActionProps;
  loading?: boolean;
  showTitleDivider?: boolean;
};

const StyledDialog = styled(Dialog)(({ theme, maxWidth }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.spacing(1),
    // For backward compatibility!
    ...(maxWidth === 'sm' && { maxWidth: theme.spacing(70) }),

    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
      width: 'calc(100% - 16px)',
    },
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  padding: theme.spacing(4, 4, 3, 4),

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2, 1, 3, 1),
  },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(0, 4, 3, 4),
  ...theme.typography.body2,

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 1, 3, 1),
  },
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(3, 4),

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2, 1),
  },
}));

export default function BinaryActionDialog(props: Props) {
  const { title, secondaryAction, primaryAction, loading, children, showTitleDivider, ...rest } = props;
  const {
    label: secondaryActionLabel,
    hidden: isSecondaryActionHidden = false,
    ...secondaryButtonProps
  } = secondaryAction;
  const { label: primaryActionLabel, hidden: isPrimaryActionHidden = false, ...primaryButtonProps } = primaryAction;

  return (
    <ThemeProvider theme={theme}>
      {/* It's very important to `disableEnforceFocus` to avoid
          `Maximum call stack size exceeded` issue. (See [1] for more details).

          [1] - https://stackoverflow.com/a/54133377     */}
      <StyledDialog fullWidth maxWidth="sm" disableEnforceFocus {...rest}>
        {loading && <LinearLoading />}
        <StyledDialogTitle>{title}</StyledDialogTitle>
        {showTitleDivider && <Divider />}
        <StyledDialogContent>{children}</StyledDialogContent>
        <Divider />
        <StyledDialogActions>
          {!isSecondaryActionHidden && (
            <Button variant="outlined" color="inherit" {...secondaryButtonProps}>
              {secondaryActionLabel}
            </Button>
          )}

          {!isPrimaryActionHidden && (
            <Button color="primary" {...primaryButtonProps}>
              {primaryActionLabel}
            </Button>
          )}
        </StyledDialogActions>
      </StyledDialog>
    </ThemeProvider>
  );
}
