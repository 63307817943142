const defaultState = {
  state: 'not_submitted',
  batchId: null,
  errors: null,
  orders: [],
  getOrderByBatchId: {},
  loading: {
    uploadBatchFile: false,
  },
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  V4_UPLOAD_BATCH_FILE_START: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        uploadBatchFile: true,
      },
    };
  },
  V4_UPDATE_BATCH_UPLOAD_PROGRESS: (state, sagaData) => {
    const { data } = sagaData;
    return {
      ...state,
      ...data,
      state: 'processing',
      loading: {
        ...state.loading,
      },
    };
  },
  V4_UPLOAD_BATCH_FILE_SUCCESSFUL: (state) => {
    return {
      ...state,
      state: 'completed',
      loading: {
        ...state.loading,
        uploadBatchFile: false,
      },
    };
  },
  V4_UPLOAD_BATCH_FILE_MISSING_INFO: (state, sagaData) => {
    const { data } = sagaData;
    return {
      ...state,
      ...data,
      state: 'missing_info',
      loading: {
        ...state.loading,
        uploadBatchFile: false,
      },
    };
  },
  V4_UPLOAD_BATCH_FILE_FAILED: (state, sagaData) => {
    const { data } = sagaData;
    return {
      ...state,
      ...data,
      state: 'failed',
      loading: {
        ...state.loading,
        uploadBatchFile: false,
      },
    };
  },
  V4_GET_ORDER_BY_BATCH_ID_SUCCESSFUL: (state, sagaData) => {
    const { data } = sagaData;
    return {
      ...state,
      orders: data.orders,
    };
  },
  V4_REQUEST_RESET_BATCH_UPLOAD_DATA: () => {
    return defaultState;
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
