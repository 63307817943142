import { isNotNull, isNumber } from './validators';

export function parseString(target) {
  return isNotNull(target) ? target : '';
}

export function parseNumber(target) {
  return isNumber(target) ? Number(target) : 0;
}

export function parseBoolean(target) {
  if (isNotNull(target)) {
    switch (target.toString().trim()) {
      case '1':
      case 'true':
        return true;
      case '0':
      case 'false':
      default:
        return false;
    }
  }

  return false;
}

export function parseJwt(jwt) {
  if (!jwt) return null;
  return JSON.parse(atob(jwt.split('.')[1]));
}
