import axios from 'axios';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

const CancelToken = axios.CancelToken;

const emptyFunc = () => {};

const defaultResult = {
  status: '',
  data: undefined,
  error: undefined,
};

/**
 *
 * @param serviceFunc function call to api
 * @param serviceParams params of service function, it is array of params
 * @param storeKey key in state store (redux, useReducer, ...)
 * @param updateStoreAction function called when add to store
 * @param lazy call api later
 * @param autoCancelRequest allow auto cancel request when call another api
 * @param singleLoad call api only once
 * @template T
 * @returns {{data: T | undefined, error: undefined, execute: (function(...[*]): void)|*, status: string}}
 * {
 *    data: data from serviceFunc
 *    error: error object from serviceFunc,
 *    execute: call this function to call api,
 *    status: one of status is: ['', 'loading', 'success', 'failed']
 * }
 */
const useService = (
  serviceFunc,
  {
    serviceParams = undefined,
    storeKey = undefined,
    updateStoreAction = emptyFunc,
    lazy = false,
    autoCancelRequest = false,
    singleLoad = false,
  } = {}
) => {
  const dispatch = useDispatch();
  const cancelTokenSourceRef = useRef(null);
  const [canExecute, setCanExecute] = useState(true);
  const [result, setResult] = useState(defaultResult);

  const execute = useCallback(
    (...args) => {
      if (!canExecute) {
        return;
      }

      setResult((prev) => ({ ...prev, status: 'loading' }));

      if (serviceFunc) {
        let _serviceParams = (args?.length ? args : serviceParams) ?? [];

        if (autoCancelRequest) {
          _serviceParams = getServiceParamsSupportCancelRequest({
            cancelTokenSourceRef,
            serviceParams: _serviceParams,
          });
        }

        return serviceFunc(..._serviceParams)
          .then((data) => {
            setResult((prev) => ({ ...prev, error: undefined, data, status: 'success' }));
            if (singleLoad) {
              setCanExecute(false);
            }
            return data;
          })
          .catch((error) => {
            setResult((prev) => ({ ...prev, error, status: 'failed' }));
            return error;
          });
      }
    },
    // Careful when put more dependency here, maybe it can make api call loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [serviceFunc, canExecute]
  );

  const resetResultState = useCallback(() => {
    setResult(defaultResult);
  }, [setResult]);

  const cleanUp = () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel('Cancel request to clean up');
    }
  };

  useEffect(() => {
    if (!lazy && canExecute) {
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel('Cancel old request and fire new request');
      }

      execute();
    }
  }, [execute, lazy, canExecute]);

  useEffect(() => {
    if (updateStoreAction && result.status) {
      const data = storeKey ? { [storeKey]: result } : result;
      const action = updateStoreAction(data, dispatch);

      if (action) {
        dispatch(updateStoreAction(data, dispatch));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, dispatch, updateStoreAction]);

  return useMemo(
    () => ({
      ...result,
      execute,
      resetResultState,
      cleanUp,
    }),
    [result, execute, resetResultState]
  );
};

function getServiceParamsSupportCancelRequest({ cancelTokenSourceRef, serviceParams }) {
  let newServiceParams = [...(serviceParams || [])];

  cancelTokenSourceRef.current = CancelToken.source();
  const cancelToken = cancelTokenSourceRef.current.token;

  // for services want to support cancel request, first service params must be object which contain `axiosCancelToken` field
  // Example: senderApi.getOrganisations
  if (newServiceParams?.[0]) {
    newServiceParams[0] = { ...newServiceParams[0], axiosCancelToken: cancelToken };
  } else {
    newServiceParams = [{ axiosCancelToken: cancelToken }];
  }

  return newServiceParams;
}

export default useService;
