import '../../styles/components/Common/header.scss';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { Box, Dialog, DialogContent, DialogTitle, styled, ThemeProvider, Typography } from '@mui/material';
import React, { Fragment, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { SENDER_TYPES } from '@yojee/helpers/constants';
import { BatchUploadIcon, BookingFormIcon } from '@yojee/ui/common/icons';
import theme from '@yojee/ui/common/theme';

import * as loginActions from '../../sagas/login/loginActions';
import BatchOrderDialog from '../Order/BatchOrderDialog';

const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    borderRadius: 8,
    padding: 15,
  },
});

const StyledButtonContainer = styled(Box)({
  display: 'flex',
  height: 120,
});

const StyledButton = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  borderRadius: 4,

  '& > .MuiTypography-root': {
    fontSize: 13,
    marginTop: 10,
    color: '#1bace0',
  },

  '& .MuiSvgIcon-root': {
    width: '1.5em',
    height: '1.5em',
  },

  '&:hover': {
    backgroundColor: '#e8f7fc',
    cursor: 'pointer',
  },
});

const ORDER_TRACKING_PATHNAME = '/order-tracking';

const Header = (props) => {
  const { useBookingPageOnlyForReports, showLoginDialog, signOut } = props;
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isOrderTrackingPage = pathname === ORDER_TRACKING_PATHNAME;

  const navigate = useNavigate();

  const [openAccountNameDropdown, setOpenAccountNameDropdown] = useState(false);
  const [openOrderCreationDialog, setOpenOrderCreationDialog] = useState(false);
  const [openBatchUploadDialog, setOpenBatchUploadDialog] = useState(false);

  const anchorEl = useRef(null);

  const handleToggle = () => {
    setOpenAccountNameDropdown(!openAccountNameDropdown);
  };

  const handleClose = (event) => {
    if (anchorEl.current.contains(event.target)) {
      return;
    }
    setOpenAccountNameDropdown(false);
  };

  const handleLogin = () => {
    if (useBookingPageOnlyForReports) {
      return navigate('/');
    }
    showLoginDialog();
    setOpenAccountNameDropdown(false);
  };

  const handleLogout = () => {
    signOut();
    if (useBookingPageOnlyForReports) {
      return navigate('/');
    } else {
      return navigate('/?tab=booking');
    }
  };

  const handleManualOrderClick = () => {
    navigate('/?tab=booking');
    setOpenOrderCreationDialog(false);
  };

  const handleBatchUploadClick = () => {
    setOpenBatchUploadDialog(true);
  };

  const handleBookDeliveryClick = () => {
    navigate('/?tab=booking');
  };

  const {
    branding: { logo },
    accountInfo,
  } = props;
  const brandingStyle = props.branding.style;
  const { senderType, id: senderId } = accountInfo;

  const primaryButton = brandingStyle?.['header-primary-button'] ?? null;
  const textColor = brandingStyle?.['header-text-color'] ?? null;

  return (
    <div className="header-container" style={brandingStyle ? brandingStyle['header-background-color'] : null}>
      <div className="header" style={textColor}>
        <Link className="logo" to="/?tab=booking" style={{ backgroundImage: `url(${logo})` }} />

        <div className="dropdown-container">
          {senderType && senderType === SENDER_TYPES.organisation && !useBookingPageOnlyForReports && (
            <Box className="action-btn" style={textColor} onClick={() => setOpenOrderCreationDialog(true)}>
              {t('Create Order')}
            </Box>
          )}

          {!!senderId && (
            <Box
              className={`action-btn ${isOrderTrackingPage ? 'disable' : ''}`}
              style={textColor}
              onClick={() => !isOrderTrackingPage && navigate(ORDER_TRACKING_PATHNAME)}
            >
              {t('Order Tracking')}
            </Box>
          )}

          {!useBookingPageOnlyForReports && (
            <div
              style={primaryButton}
              className="btn btn-primary book-delivery-link unset-background-color"
              onClick={handleBookDeliveryClick}
            >
              <Trans>Book a Delivery</Trans>
            </div>
          )}

          {senderId ? (
            <Fragment>
              <span
                ref={anchorEl}
                aria-owns={openAccountNameDropdown ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                className="link"
                style={textColor}
                onClick={handleToggle}
                data-cy="account-name"
              >
                {accountInfo.name}
              </span>
              <Popper
                open={openAccountNameDropdown}
                anchorEl={anchorEl?.current}
                placement="bottom-end"
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    id="menu-list-grow"
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList className="menu-container">
                          <MenuItem onClick={handleLogout} data-cy="logout-item">
                            <Trans>Log Out</Trans>
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Fragment>
          ) : (
            <button
              disabled={props.isShowLoginDialog}
              style={primaryButton}
              className="btn btn-primary unset-background-color"
              onClick={handleLogin}
              data-cy="show-login-form-button"
            >
              <Trans>Log In</Trans>
            </button>
          )}
        </div>
      </div>

      <ThemeProvider theme={theme}>
        <StyledDialog
          open={openOrderCreationDialog}
          fullWidth
          maxWidth="sm"
          onClose={() => setOpenOrderCreationDialog(false)}
        >
          <DialogTitle fontSize={20} fontWeight="bold">
            {t('Create Order')}
          </DialogTitle>
          <DialogContent>
            <StyledButtonContainer>
              <StyledButton onClick={handleManualOrderClick}>
                <BookingFormIcon />
                <Typography>{t('Manual order')}</Typography>
              </StyledButton>

              <StyledButton onClick={handleBatchUploadClick} disabled={!senderId}>
                <BatchUploadIcon />
                <Typography>{t('Batch upload')}</Typography>
              </StyledButton>

              {!!senderId && openBatchUploadDialog && (
                <BatchOrderDialog open={openBatchUploadDialog} onClose={() => setOpenBatchUploadDialog(false)} />
              )}
            </StyledButtonContainer>
          </DialogContent>
        </StyledDialog>
      </ThemeProvider>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    branding: state.company.companyInfo.branding,
    accountInfo: state.login.accountInfo,
    isShowLoginDialog: state.login.componentState.isShowLoginDialog,
    useBookingPageOnlyForReports: state.company.companyInfo.useBookingPageOnlyForReports,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(loginActions.signOut()),
    showLoginDialog: () => dispatch(loginActions.showLoginDialog()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
