import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import { authService } from '@yojee/api/authService';
import { authUtilsAdapter } from '@yojee/auth/utils';

import createRootReducer from '../reducers';
import sagas from '../sagas';
import { authService as bookingAuthService } from '../services/authService';
import { commonService as bookingCommonService } from '../services/commonService';

const sagaMiddleware = createSagaMiddleware();

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

authService.setProvider(bookingAuthService);
authUtilsAdapter.setRunner(bookingCommonService);

export const store = configureStore({
  reducer: createRootReducer(routerReducer),
  middleware: [sagaMiddleware, routerMiddleware],
  devTools: process.env.NODE_ENV === 'development',
});

// We use this store to allow axios middleware to access the store state
// without have circle dependency
window.bookingReduxStore = store;

export const history = createReduxHistory(store);

export const persistor = persistStore(store);

sagaMiddleware.run(sagas);
