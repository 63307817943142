import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import BaseNoPermission from '@yojee/ui/base/houses/NoPermission';

import Layout from './Layout';

const useStyles = makeStyles({
  noPermission: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    '& .banner-container': {
      flexShrink: 0,
    },
    '& .content-container': {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
    },
  },
  baseNoPermission: {
    height: 'auto',
  },
});

function NoPermission() {
  const classes = useStyles();
  const isLoggedIn = useSelector((state) => state.login.accountInfo.accessToken);

  if (!isLoggedIn) return <Navigate to="/" />;

  return (
    <Layout className={classes.noPermission}>
      <BaseNoPermission className={classes.baseNoPermission} />
    </Layout>
  );
}

export default NoPermission;
