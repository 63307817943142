import '../../styles/components/Login/LoginForm.scss';

import Snackbar from '@material-ui/core/Snackbar';
import React, { Component, Fragment } from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';

import * as loginActions from '../../sagas/login/loginActions';
import { CommonService } from '../../services/commonService';
import {
  isIncludeNumber,
  isIncludeSpecialChars,
  isLowerCase,
  isNumber,
  isUpperCase,
  isValidEmail,
  isValidOtp,
  isValidPassword,
} from '../../utils';

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      phoneNumber: '',
      otpCode: '',
      loginMode: 'email',
      phoneCodeList: CommonService.getPhoneCodeList(),
      emailLoginForm: {
        email: '',
        password: '',
        validators: {
          email: ['email'],
        },
        errors: {
          email: [],
        },
        touched: {
          email: false,
        },
      },
      getOtpForm: {
        countryCode: '',
        phone: '',
        validators: {
          phone: ['number'],
        },
        errors: {
          phone: [],
        },
        touched: {
          phone: false,
        },
      },
      otpConfirmationForm: {
        countryCode: '',
        phone: '',
        otp: '',
        validators: {
          phone: ['number'],
          otp: ['otp'],
        },
        errors: {
          phone: [],
          otp: [],
        },
        touched: {
          phone: false,
          otp: false,
        },
      },
    };
  }

  renderError = () => {
    const { error } = this.props;
    if (error && error.failed) {
      return (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={error.failed}
          autoHideDuration={4000}
          onClose={this.props.resetErrors}
          message={<span className="my-translated-paragraph">{error.message}</span>}
        />
      );
    }
  };

  renderLoginByEmail = () => {
    const { errors, touched, email, password } = this.state.emailLoginForm;
    const { loading } = this.props;
    return (
      <form onSubmit={this.loginByEmail}>
        <div className={'form-group' + (touched.email && errors.email.length > 0 ? ' has-error' : '')}>
          <div className="label-control">
            <label htmlFor="login-email">
              <Trans>Email Address</Trans> *
            </label>
          </div>
          <div className="form-control">
            <input
              type="email"
              className="field"
              id="login-email"
              onChange={this.onFieldChange('emailLoginForm', 'email')}
              onBlur={this.onFieldBlur('emailLoginForm', 'email')}
              data-cy="login-email-input"
            />
          </div>
          {touched.email && errors.email.length > 0 ? <p className="error-message">{errors.email}</p> : null}
        </div>
        <div className="form-group">
          <label id="loginPasswordLabel" htmlFor="login-password">
            <Trans>Password</Trans> *
            <span data-cy="login-forgot-password" className="link" onClick={this.props.showResetPasswordDialog}>
              <Trans>Forgot Password?</Trans>
            </span>
          </label>
          <div className="form-control">
            <input
              type="password"
              className="field"
              id="login-password"
              onChange={this.onFieldChange('emailLoginForm', 'password')}
              data-cy="login-email-password"
            />
          </div>
        </div>
        <div className="button-container">
          <button
            type="submit"
            className={'btn btn-primary' + (loading.login || loading.getSenderInfo ? ' loading' : '')}
            disabled={!email || errors.email.length || !password || loading.login || loading.getSenderInfo}
            data-cy="login-button"
          >
            <Trans>Log in</Trans>
          </button>
        </div>
      </form>
    );
  };

  renderGetOtpCode = () => {
    const { errors, touched, phone } = this.state.getOtpForm;
    const { loading } = this.props;

    return (
      <form onSubmit={this.getOtpCode}>
        <div className={'form-group' + (touched.phone && errors.phone.length > 0 ? ' has-error' : '')}>
          <div className="label-control">
            <label htmlFor="login-phone">
              <Trans>Phone Number</Trans> *
            </label>
            <span className="link" onClick={this.changeLoginMethod('email')}>
              <Trans>Email Login</Trans>
            </span>
          </div>
          <div className="form-control fields-group">
            <select className="field country-code-select" onChange={this.onFieldChange('getOtpForm', 'countryCode')}>
              {this.state.phoneCodeList.map((code) => (
                <option key={`login-code-${code}`} value={`+${code}`}>
                  {code}
                </option>
              ))}
            </select>
            <input
              className="field"
              type="number"
              id="login-phone"
              onChange={this.onFieldChange('getOtpForm', 'phone')}
            />
          </div>
          {touched.phone && errors.phone.length > 0 ? <p className="error-message">{errors.phone}</p> : null}
        </div>
        <div className="button-container">
          <button
            type="submit"
            className={'btn btn-primary' + (loading.getOtp ? ' loading' : '')}
            disabled={!phone || errors.phone.length || loading.getOtp}
          >
            <Trans>Get OTP</Trans>
          </button>
        </div>
      </form>
    );
  };

  renderLoginByPhone = () => {
    const { errors, touched, phone, countryCode, otp } = this.state.otpConfirmationForm;
    const { loading } = this.props;

    return (
      <form onSubmit={this.loginByPhone}>
        <div className={'form-group' + (touched.phone && errors.phone.length > 0 ? ' has-error' : '')}>
          <div className="label-control">
            <label htmlFor="login-phone">
              <Trans>Phone Number</Trans> *
            </label>
            <span className="link" onClick={this.changeLoginMethod('email')}>
              <Trans>Email Login</Trans>
            </span>
          </div>
          <div className="form-control fields-group">
            <select
              className="field country-code-select"
              defaultValue={countryCode}
              onChange={this.onFieldChange('otpConfirmationForm', 'countryCode')}
            >
              {this.state.phoneCodeList.map((code) => (
                <option key={`login-code-${code}`} value={`+${code}`}>
                  {code}
                </option>
              ))}
            </select>
            <input
              className="field"
              type="number"
              id="login-phone"
              onChange={this.onFieldChange('otpConfirmationForm', 'phone')}
              onBlur={this.onFieldBlur('otpConfirmationForm', 'phone')}
              defaultValue={phone}
            />
          </div>
          {touched.phone && errors.phone.length > 0 ? <p className="error-message">{errors.phone}</p> : null}
        </div>
        <div className={'form-group' + (touched.otp && errors.otp.length > 0 ? ' has-error' : '')}>
          <label htmlFor="login-otp">
            <Trans>One Time Password</Trans> *
          </label>
          <div className="form-control otp-form-control">
            <input
              type="number"
              className="field"
              id="login-otp"
              onChange={this.onFieldChange('otpConfirmationForm', 'otp')}
              onBlur={this.onFieldBlur('otpConfirmationForm', 'otp')}
            />
            <span className="resend-otp-link link" onClick={this.resendOtp}>
              {loading.getOtp ? (
                <Fragment>
                  <Trans>Sending</Trans>...
                </Fragment>
              ) : (
                <Trans>Resend OTP</Trans>
              )}
            </span>
          </div>
          {touched.otp && errors.otp.length > 0 ? <p className="error-message">{errors.otp}</p> : null}
        </div>
        <div className="button-container">
          <button
            type="submit"
            className={'btn btn-primary' + (loading.login || loading.getSenderInfo ? ' loading' : '')}
            disabled={
              !otp ||
              errors.otp.length ||
              !phone ||
              errors.phone.length ||
              loading.login ||
              loading.getSenderInfo ||
              loading.getOtp
            }
          >
            <Trans>Log in</Trans>
          </button>
        </div>
      </form>
    );
  };

  onFieldChange = (form, field) => (event) => {
    const _target = event.target;
    const value = _target.type === 'checkbox' ? _target.checked : _target.value;
    this.setState(
      {
        [form]: {
          ...this.state[form],
          [field]: value,
        },
      },
      () => this.validateField(form, field, value)
    );
  };

  onFieldBlur = (form, field) => (event) => {
    this.setState({
      [form]: {
        ...this.state[form],
        touched: {
          ...this.state[form].touched,
          [field]: true,
        },
      },
    });
  };

  validateField = (form, field, value) => {
    if (this.state[form].validators[field]) {
      const errors = [];
      this.state[form].validators[field].forEach((validator) => {
        switch (validator) {
          case 'email':
            if (!isValidEmail(value)) {
              errors.push(<Trans key="invalid-email">Invalid Email</Trans>);
            }
            break;
          case 'password':
            if (!value) {
              errors.push(<Trans key={`${field}-error-validator`} i18nKey="Field is required" />);
            }
            if (!isValidPassword(value)) {
              errors.push(<Trans key={`${field}-error-validator-password-length`} i18nKey="password-length-require" />);
            }

            if (!isLowerCase(value)) {
              errors.push(<Trans key={`${field}-error-validator-lower-case`} i18nKey="invalid-lower-case" />);
            }

            if (!isUpperCase(value)) {
              errors.push(<Trans key={`${field}-error-validator-upper-case`} i18nKey="invalid-upper-case" />);
            }

            if (!isIncludeNumber(value)) {
              errors.push(<Trans key={`${field}-error-validator-include-number`} i18nKey="invalid-include-number" />);
            }

            if (!isIncludeSpecialChars(value)) {
              errors.push(<Trans key={`${field}-error-validator-special-chars`} i18nKey="invalid-special-chars" />);
            }
            break;
          case 'phone':
            if (!isNumber(value)) {
              errors.push(<Trans key="invalid-phone-number">Phone number should contain only digits</Trans>);
            }
            break;
          case 'otp':
            if (!isValidOtp(value)) {
              errors.push(<Trans key="invalid-otp">OTP is invalid</Trans>);
            }
            break;
        }
      });

      this.setState({
        [form]: {
          ...this.state[form],
          errors: {
            ...this.state[form].errors,
            [field]: errors,
          },
        },
      });
    }
  };

  changeLoginMethod = (mode) => {
    return (e) => {
      e.preventDefault();
      this.setState((state) => ({
        loginMode: mode,
      }));
    };
  };

  loginByEmail = (event) => {
    event.preventDefault();
    const { email, password } = this.state.emailLoginForm;
    this.props.loginByEmail({ email, password });
  };

  getOtpCode = (event) => {
    event.preventDefault();
    const { countryCode, phone } = this.state.getOtpForm;
    this.setState({
      otpConfirmationForm: {
        ...this.state.otpConfirmationForm,
        countryCode,
        phone,
      },
    });
    this.props.getOtpCode({ phoneNumber: `${countryCode}${phone}` });
  };

  resendOtp = () => {
    if (!this.props.loading.getOtp) {
      const { countryCode, phone } = this.state.otpConfirmationForm;
      this.props.getOtpCode({ phoneNumber: `${countryCode}${phone}` });
    }
  };

  loginByPhone = (event) => {
    event.preventDefault();
    const { countryCode, phone, otp } = this.state.otpConfirmationForm;
    this.props.loginByPhone({ phoneNumber: `${countryCode}${phone}`, otpCode: otp });
  };

  render() {
    const { otpCodeSent } = this.props;
    const { loginMode } = this.state;

    return (
      <>
        <div className="body">
          <div className="login-container" id="login">
            <h2>
              <Trans>Existing User</Trans>
            </h2>
            {!this.props.useBookingPageOnlyForReports && (
              <p className="info">
                <Trans i18nKey="loginIntoAccount">Log in to your account to book.</Trans>
              </p>
            )}
            {this.renderError()}
            {loginMode === 'email' && this.renderLoginByEmail()}
            {loginMode === 'phone' && !otpCodeSent && this.renderGetOtpCode()}
            {loginMode === 'phone' && otpCodeSent && this.renderLoginByPhone()}
            <p className="do-another-action" style={{ display: 'none' }}>
              <Trans i18nKey="doNotHaveAccount">Don’t have an account?</Trans>{' '}
              <a href="#sign-up" className="link">
                <Trans>Sign Up</Trans>
              </a>
            </p>
          </div>
          {this.props.children ? this.props.children : null}
        </div>
        {!this.props.isLoginRequired && this.props && <span className="close" onClick={this.props.closeDialog} />}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.login.error,
    loading: state.login.loading,
    otpCodeSent: state.login.componentState.otpCodeSent,
    isLoginRequired: state.login.componentState.isLoginRequired,
    useBookingPageOnlyForReports: state.company?.companyInfo?.useBookingPageOnlyForReports,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginByEmail: (payload) => dispatch(loginActions.loginByEmail(payload)),
    loginByPhone: (payload) => dispatch(loginActions.loginByPhoneNumber(payload)),
    getOtpCode: (payload) => dispatch(loginActions.getOtpCode(payload)),
    resetErrors: () => dispatch(loginActions.resetErrors()),
    showResetPasswordDialog: () => dispatch(loginActions.showResetPasswordDialog()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
