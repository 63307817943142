import './TimeZoneContent.scss';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import React, { useState } from 'react';

import CopyToClipBoardBtn from '../CopyToClipBoardBtn';
import { getComparator, getTimeZoneLists, stableSort, TIMEZONE_HEAD_CELLS } from '../helper';
import SearchBox from '../SearchBox';
import TimezoneTableHeader from './TimezoneTableHeader';

const styles = {
  container: {
    height: '560px',
    width: '1000px',
  },
  actionCancel: {
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.23)',
  },
  timezoneRow: {
    height: '40px',
    '& .teams': {
      whiteSpace: 'pre-wrap',
      marginTop: '5px',
      marginBottom: '5px',
    },
  },
  tableContainer: {
    maxHeight: '450px',
  },
};

const TimeZoneContent = (props) => {
  const { classes } = props;
  const timezones = getTimeZoneLists();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('timezoneName');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setRows] = useState(timezones);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = timezones.filter((row) => {
      return (
        row.timezoneName.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.countryCode.toLowerCase().includes(searchedVal.toLowerCase())
      );
    });
    setRows(filteredRows);
  };

  return (
    <Grid container className={classes.container} spacing={1}>
      <Grid item xs={12}>
        <SearchBox onSearch={requestSearch} debounceTime={500} />
        <TableContainer className={classes.tableContainer}>
          <Table size="medium" key="table-timezone-management" className="table timezone-list">
            <TimezoneTableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={TIMEZONE_HEAD_CELLS}
            />
            <TableBody className="table-body">
              {(rowsPerPage > 0
                ? stableSort(rows, getComparator(order, orderBy)).slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : rows
              ).map((row) => (
                <TableRow hover className={classes.timezoneRow} key={row.timezoneName}>
                  <TableCell align="left" className="cell-content">
                    {row.countryCode}
                  </TableCell>
                  <TableCell align="left" className="cell-content">
                    <CopyToClipBoardBtn content={row.timezoneName} />
                  </TableCell>
                  <TableCell align="right" className="cell-content">
                    {row.utcOffset}
                  </TableCell>
                  <TableCell align="right" className="cell-content">
                    {row.utcDstOffset}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, 200, 500]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(TimeZoneContent);
