import { SvgIcon, SvgIconProps } from '@mui/material';

const BatchUploadIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="36px"
      height="32px"
      viewBox="0 0 36 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>Icon/Batch Upload</title>
      <g id="Icon/Batch-Upload" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M1,32 C0.44771525,32 0,31.5522847 0,31 L0,1 C-6.76353751e-17,0.44771525 0.44771525,6.76353751e-17 1,0 L35,0 C35.5522847,-1.01453063e-16 36,0.44771525 36,1 L36,31 C36,31.5128358 35.6139598,31.9355072 35.1166211,31.9932723 L35,32 L1,32 Z M8,24 L2,24 L2,30 L8,30 L8,24 Z M21,24 L10,24 L10,30 L21,30 L21,24 Z M34,24 L23,24 L23,30 L34,30 L34,24 Z M8,16 L2,16 L2,22 L8,22 L8,16 Z M21,16 L10,16 L10,22 L21,22 L21,16 Z M34,16 L23,16 L23,22 L34,22 L34,16 Z M8,8 L2,8 L2,14 L8,14 L8,8 Z M21,8 L10,8 L10,14 L21,14 L21,8 Z M34,8 L23,8 L23,14 L34,14 L34,8 Z"
          id="Icon-Spreadsheet"
          fill="#1BACE0"
        />
      </g>
    </SvgIcon>
  );
};

export default BatchUploadIcon;
