import { styled } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import React from 'react';

const StyledButton = styled((props: ButtonProps) => <Button disableElevation variant="contained" {...props} />)(
  ({ theme, color }) => ({
    ...theme.typography.body1,
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    height: theme.spacing(5.5),
    borderRadius: theme.spacing(1),
    '&.MuiButton-outlinedInherit': {
      border: '1px solid rgba(4, 9, 33, 0.24)',
    },
    '&.MuiButton-sizeSmall': {
      ...theme.typography.body2,
      fontWeight: theme.typography.fontWeightBold,
      height: theme.spacing(4),
      borderRadius: theme.spacing(0.75),
    },
    ...(color === 'warning' && { color: '#000000' }),
  })
);

export default StyledButton;
