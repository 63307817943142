import { push } from 'redux-first-history';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { CUSTOM_EVENT_NAMES, triggerWindowEvent } from '@yojee/helpers/triggerWindowEvent';

import { loginService } from '../../services/loginService';
export const loginData = (state) => state.login;

export default function* sagas() {
  yield takeLatest('REQUEST_LOGIN_BY_EMAIL', loginByEmail);
  yield takeLatest('LOGIN_SUCCESSFUL', onLoginSuccessfull);
  yield takeLatest('REQUEST_GET_OTP_CODE', getOtpCode);
  yield takeLatest('REQUEST_LOGIN_BY_PHONE_NUMBER', loginByPhoneNumber);
  yield takeLatest('REQUEST_GET_SENDER_INFO', getSenderInfo);
  yield takeLatest('RESET_ERRORS', resetErrors);

  yield takeLatest('SIGN_OUT', signOut);
  yield takeLatest('REQUEST_SEND_PASSWORD_RESET_EMAIL', sendPasswordResetEmail);
  yield takeLatest('REQUEST_SHOW_LOGIN_DIALOG', showLoginDialog);
  yield takeLatest('REQUEST_HIDE_LOGIN_DIALOG', hideLoginDialog);
  yield takeLatest('REQUEST_SHOW_RESET_PASSWORD_DIALOG', showResetPasswordDialog);
  yield takeLatest('REQUEST_HIDE_RESET_PASSWORD_DIALOG', hideResetPasswordDialog);
  yield takeLatest('REQUEST_UPDATE_PASSWORD', updatePassword);
}

function onLoginSuccessfull({ data: userData }) {
  triggerWindowEvent(CUSTOM_EVENT_NAMES.login, userData);
}

function* loginByEmail(action) {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'login' });
    const data = yield call(loginService.loginByEmail, action.payload);
    yield put({ type: 'LOGIN_SUCCESSFUL', data });
    yield put({ type: 'REQUEST_GET_SENDER_INFO', data });
  } catch (error) {
    yield put({ type: 'LOGIN_FAILED', error });
  }
}

function* getOtpCode(action) {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'getOtp' });
    const data = yield call(loginService.getOtpCode, action.payload);
    yield put({ type: 'GET_OTP_CODE_SUCCESSFUL', data });
  } catch (error) {
    yield put({ type: 'GET_OTP_CODE_FAILED', error });
  }
}

function* loginByPhoneNumber(action) {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'login' });
    const data = yield call(loginService.loginByPhoneNumber, action.payload);
    yield put({ type: 'LOGIN_SUCCESSFUL', data });
    yield put({ type: 'REQUEST_GET_SENDER_INFO', data });
  } catch (error) {
    yield put({ type: 'LOGIN_FAILED', error });
  }
}

function* getSenderInfo() {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'getSenderInfo' });
    const data = yield call(loginService.getSenderInfo);
    yield put({ type: 'GET_SENDER_INFO_SUCCESSFUL', data });
  } catch (error) {
    yield put({ type: 'GET_SENDER_INFO_FAILED', error });
  }
}

function* sendPasswordResetEmail(action) {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'sendPasswordResetEmail' });
    const { accountInfo } = yield select(loginData);
    const { slug } = accountInfo;
    const payload = { ...action.payload, company_slug: slug, portal: 'book' };
    const data = yield call(loginService.sendPasswordResetEmail, payload);
    yield put({ type: 'SEND_PASSWORD_RESET_EMAIL_SUCCESSFUL', data });
  } catch (error) {
    yield put({ type: 'SEND_PASSWORD_RESET_EMAIL_FAILED', error });
  }
}

function* updatePassword(action) {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'updatePassword' });
    const data = yield call(loginService.updatePassword, action.payload);
    yield put(push('/'));
    yield put({ type: 'UPDATE_PASSWORD_SUCCESSFUL', data });
  } catch (error) {
    yield put({ type: 'UPDATE_PASSWORD_FAILED', error });
  }
}

function* resetErrors() {
  yield put({ type: 'GET_RESET_ERRORS' });
}

function* signOut() {
  try {
    yield call(loginService.signOut);
    yield put({ type: 'SIGN_OUT_SUCCESSFUL' });
    triggerWindowEvent(CUSTOM_EVENT_NAMES.logout);
  } catch (error) {
    console.log(error);
  }
}

function* showLoginDialog(action) {
  const isLoginRequired = !!action.payload;
  yield put({ type: 'HIDE_RESET_PASSWORD_DIALOG' });
  yield put({ type: 'SHOW_LOGIN_DIALOG', isLoginRequired });
}

function* hideLoginDialog() {
  yield put({ type: 'HIDE_LOGIN_DIALOG' });
}

function* showResetPasswordDialog() {
  yield put({ type: 'HIDE_LOGIN_DIALOG' });
  yield put({ type: 'SHOW_RESET_PASSWORD_DIALOG' });
}

function* hideResetPasswordDialog() {
  yield put({ type: 'HIDE_RESET_PASSWORD_DIALOG' });
}
