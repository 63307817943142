const defaultState = {
  landingCompany: {},
  instantQuotes: [],
  trackItem: null,
  showError: false,
  error: {
    failed: false,
    message: null,
  },
  showCost: false,
  loading: {
    getInstantQuote: false,
    getTrackInfo: false,
  },
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  GET_BOOKING_INFO_SUCCESSFUL: (state, sagaData) => {
    const data = sagaData.data;
    return {
      ...state,
      landingCompany: data,
    };
  },
  GET_BOOKING_INFO_FAILED: (state, sagaData) => {
    const { error } = sagaData;
    return {
      ...state,
      landingCompany: {},
      error: error,
    };
  },
  GET_INSTANT_QUOTE_SUCCESSFUL: (state, sagaData) => {
    const data = sagaData.data;
    return {
      ...state,
      instantQuotes: data,
      showCost: true,
      loading: { ...state.loading, getInstantQuote: false },
    };
  },
  GET_INSTANT_QUOTE_PUBLIC_FAILED: (state) => {
    return {
      ...state,
      instantQuotes: [],
      showCost: true,
      loading: { ...state.loading, getInstantQuote: false },
    };
  },
  CLEAR_INSTANT_QUOTE_DATA: (state) => {
    return {
      ...state,
      instantQuotes: [],
      showCost: false,
    };
  },
  GET_ORDER_TRACK: (state, sagaData) => {
    const data = sagaData.data;
    return {
      ...state,
      trackOrder: data,
      error: null,
      showError: false,
    };
  },
  GET_ORDER_TRACK_FAILED: (state, sagaData) => {
    const { error } = sagaData;
    return {
      ...state,
      error: error,
      trackOrder: null,
    };
  },
  GET_ITEM_TRACK: (state, sagaData) => {
    const data = sagaData.data;
    return {
      ...state,
      trackItem: data,
      error: null,
      showError: false,
    };
  },
  GET_ITEM_TRACK_FAILED: (state, sagaData) => {
    const { error } = sagaData;
    return {
      ...state,
      error: error,
      showError: true,
    };
  },
  START_LOADING: (state, sagaData) => {
    console.log(sagaData.loadingAction);
    return {
      ...state,
      loading: { ...state.loading, [sagaData.loadingAction]: true },
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
