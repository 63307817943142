import './styles/main.scss';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import { SnackbarProvider } from 'notistack';
import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { initGA } from '@yojee/helpers/GAHelper';
import lazyLoadComponent from '@yojee/helpers/lazyLoadComponent';
import { legacyTheme } from '@yojee/ui/base/theme';
import { HistoryRouter } from '@yojee/ui/router/Router';

import packageJson from '../../../package.json';
import BookingPageContentHoc from './components/BookingPageContentHOC';
import NoPermission from './components/Common/NoPermission';

const Home = lazyLoadComponent(() => import('./components/Home'));
const NewMyOrder = lazyLoadComponent(() => import('./components/NewMyOrder'));
const SignUp = lazyLoadComponent(() => import('./components/NewLogin/SignUp'));
const ForgotPassword = lazyLoadComponent(() => import('./components/NewLogin/ForgotPassword'));
const NewLogin = lazyLoadComponent(() => import('./components/NewLogin'));
const LandingPage = lazyLoadComponent(() => import('./components/Landing/LandingPage'));

const OrdersTrackingReport = lazyLoadComponent(() => import('./components/ordersTrackingReport'));

const anchorOrigin = { vertical: 'top', horizontal: 'center' };

window.appVersion = window.appVersion || packageJson.version;

initGA('booking', window.appVersion);

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

const App = ({ history }) => (
  <>
    <CssBaseline />
    <HistoryRouter history={history}>
      <MuiThemeProvider theme={legacyTheme}>
        <SnackbarProvider autoHideDuration={3000} anchorOrigin={anchorOrigin}>
          <Suspense fallback={<div />}>
            <BookingPageContentHoc>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/order/my" element={<Navigate replace to="/order-tracking" />} />
                <Route path="/password/edit" element={<Home />} />
                <Route path="/login" element={<NewLogin />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/landing" element={<LandingPage />} />
                <Route path="/new-my-order" element={<NewMyOrder />} />
                <Route path="/no-permission" element={<NoPermission />} />
                <Route path="/order-tracking" element={<OrdersTrackingReport />} />
              </Routes>
            </BookingPageContentHoc>
          </Suspense>
        </SnackbarProvider>
      </MuiThemeProvider>
    </HistoryRouter>
  </>
);

export default App;
