import { BookingBaseService } from '@yojee/api/baseService/bookingBaseService';

import { authService } from './authService';

export class LandingService extends BookingBaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  extractData(response) {
    return response.data;
  }
  getBookingInfo = () => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4('public/booking_portal/company'))
      .then((response) => {
        const result = this.extractData(response);
        return result.data;
      })
      .catch((error) => this.handleError(error));
  };

  getInstantQuotePublic = (payload) => {
    return this.authService
      .post(this.getUmbrellaApiUrlV4('public/booking_portal/quotes/search'), JSON.stringify(payload))
      .then((response) => {
        const result = this.extractData(response);
        return result.data;
      })
      .catch((error) => this._handleError(error));
  };

  getInstantQuoteSender = (payload) => {
    return this.authService
      .post(this.getUmbrellaApiUrlV4('sender/booking_portal/quotes/search'), JSON.stringify(payload))
      .then((response) => {
        const result = this.extractData(response);
        return result.data;
      })
      .catch((error) => this.handleError(error));
  };

  getOrderTrackingData = (trackingId) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`public/track/${trackingId}`))
      .then((response) => {
        const result = this.extractData(response);
        return result.data;
      })
      .catch((error) => this.handleError(error));
  };

  getItemTrackingData = (trackingId) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`public/track_item/${trackingId}`))
      .then((response) => {
        const result = this.extractData(response);
        return result.data;
      })
      .catch((error) => this.handleError(error));
  };
}

export const landingService = new LandingService({ authService });
