const countries = [
  {
    name: 'Singapore',
    iso: 'sg',
    code: '65',
  },
  {
    name: 'Afghanistan',
    iso: 'af',
    code: '93',
  },
  {
    name: 'Albania',
    iso: 'al',
    code: '355',
  },
  {
    name: 'Algeria',
    iso: 'dz',
    code: '213',
  },
  {
    name: 'American Samoa',
    iso: 'as',
    code: '1684',
  },
  {
    name: 'Andorra',
    iso: 'ad',
    code: '376',
  },
  {
    name: 'Angola',
    iso: 'ao',
    code: '244',
  },
  {
    name: 'Anguilla',
    iso: 'ai',
    code: '1264',
  },
  {
    name: 'Antigua and Barbuda',
    iso: 'ag',
    code: '1268',
  },
  {
    name: 'Argentina',
    iso: 'ar',
    code: '54',
  },
  {
    name: 'Armenia',
    iso: 'am',
    code: '374',
  },
  {
    name: 'Aruba',
    iso: 'aw',
    code: '297',
  },
  {
    name: 'Australia',
    iso: 'au',
    code: '61',
  },
  {
    name: 'Austria',
    iso: 'at',
    code: '43',
  },
  {
    name: 'Azerbaijan',
    iso: 'az',
    code: '994',
  },
  {
    name: 'Bahamas',
    iso: 'bs',
    code: '1242',
  },
  {
    name: 'Bahrain',
    iso: 'bh',
    code: '973',
  },
  {
    name: 'Bangladesh',
    iso: 'bd',
    code: '880',
  },
  {
    name: 'Barbados',
    iso: 'bb',
    code: '1246',
  },
  {
    name: 'Belarus',
    iso: 'by',
    code: '375',
  },
  {
    name: 'Belgium',
    iso: 'be',
    code: '32',
  },
  {
    name: 'Belize',
    iso: 'bz',
    code: '501',
  },
  {
    name: 'Benin',
    iso: 'bj',
    code: '229',
  },
  {
    name: 'Bermuda',
    iso: 'bm',
    code: '1441',
  },
  {
    name: 'Bhutan',
    iso: 'bt',
    code: '975',
  },
  {
    name: 'Bolivia',
    iso: 'bo',
    code: '591',
  },
  {
    name: 'Bosnia and Herzegovina',
    iso: 'ba',
    code: '387',
  },
  {
    name: 'Botswana',
    iso: 'bw',
    code: '267',
  },
  {
    name: 'Brazil',
    iso: 'br',
    code: '55',
  },
  {
    name: 'British Indian Ocean Territory',
    iso: 'io',
    code: '246',
  },
  {
    name: 'British Virgin Islands',
    iso: 'vg',
    code: '1284',
  },
  {
    name: 'Brunei',
    iso: 'bn',
    code: '673',
  },
  {
    name: 'Bulgaria',
    iso: 'bg',
    code: '359',
  },
  {
    name: 'Burkina Faso',
    iso: 'bf',
    code: '226',
  },
  {
    name: 'Burundi',
    iso: 'bi',
    code: '257',
  },
  {
    name: 'Cambodia',
    iso: 'kh',
    code: '855',
  },
  {
    name: 'Cameroon',
    iso: 'cm',
    code: '237',
  },
  {
    name: 'Canada',
    iso: 'ca',
    code: '1',
  },
  {
    name: 'Cape Verde',
    iso: 'cv',
    code: '238',
  },
  {
    name: 'Caribbean Netherlands',
    iso: 'bq',
    code: '599',
  },
  {
    name: 'Cayman Islands',
    iso: 'ky',
    code: '1345',
  },
  {
    name: 'Central African Republic',
    iso: 'cf',
    code: '236',
  },
  {
    name: 'Chad',
    iso: 'td',
    code: '235',
  },
  {
    name: 'Chile',
    iso: 'cl',
    code: '56',
  },
  {
    name: 'China',
    iso: 'cn',
    code: '86',
  },
  {
    name: 'Colombia',
    iso: 'co',
    code: '57',
  },
  {
    name: 'Comoros',
    iso: 'km',
    code: '269',
  },
  {
    name: 'Congo (DRC)',
    iso: 'cd',
    code: '243',
  },
  {
    name: 'Congo (Republic)',
    iso: 'cg',
    code: '242',
  },
  {
    name: 'Cook Islands',
    iso: 'ck',
    code: '682',
  },
  {
    name: 'Costa Rica',
    iso: 'cr',
    code: '506',
  },
  {
    name: 'CÃ´te dâ€™Ivoire',
    iso: 'ci',
    code: '225',
  },
  {
    name: 'Croatia',
    iso: 'hr',
    code: '385',
  },
  {
    name: 'Cuba',
    iso: 'cu',
    code: '53',
  },
  {
    name: 'CuraÃ§ao',
    iso: 'cw',
    code: '599',
  },
  {
    name: 'Cyprus',
    iso: 'cy',
    code: '357',
  },
  {
    name: 'Czech Republic',
    iso: 'cz',
    code: '420',
  },
  {
    name: 'Denmark',
    iso: 'dk',
    code: '45',
  },
  {
    name: 'Djibouti',
    iso: 'dj',
    code: '253',
  },
  {
    name: 'Dominica',
    iso: 'dm',
    code: '1767',
  },
  {
    name: 'Dominican Republic',
    iso: 'do',
    code: '1',
  },
  {
    name: 'Ecuador',
    iso: 'ec',
    code: '593',
  },
  {
    name: 'Egypt',
    iso: 'eg',
    code: '20',
  },
  {
    name: 'El Salvador',
    iso: 'sv',
    code: '503',
  },
  {
    name: 'Equatorial Guinea',
    iso: 'gq',
    code: '240',
  },
  {
    name: 'Eritrea',
    iso: 'er',
    code: '291',
  },
  {
    name: 'Estonia',
    iso: 'ee',
    code: '372',
  },
  {
    name: 'Ethiopia',
    iso: 'et',
    code: '251',
  },
  {
    name: 'Falkland Islands',
    iso: 'fk',
    code: '500',
  },
  {
    name: 'Faroe Islands',
    iso: 'fo',
    code: '298',
  },
  {
    name: 'Fiji',
    iso: 'fj',
    code: '679',
  },
  {
    name: 'Finland',
    iso: 'fi',
    code: '358',
  },
  {
    name: 'France',
    iso: 'fr',
    code: '33',
  },
  {
    name: 'French Guiana',
    iso: 'gf',
    code: '594',
  },
  {
    name: 'French Polynesia',
    iso: 'pf',
    code: '689',
  },
  {
    name: 'Gabon',
    iso: 'ga',
    code: '241',
  },
  {
    name: 'Gambia',
    iso: 'gm',
    code: '220',
  },
  {
    name: 'Georgia',
    iso: 'ge',
    code: '995',
  },
  {
    name: 'Germany',
    iso: 'de',
    code: '49',
  },
  {
    name: 'Ghana',
    iso: 'gh',
    code: '233',
  },
  {
    name: 'Gibraltar',
    iso: 'gi',
    code: '350',
  },
  {
    name: 'Greece',
    iso: 'gr',
    code: '30',
  },
  {
    name: 'Greenland',
    iso: 'gl',
    code: '299',
  },
  {
    name: 'Grenada',
    iso: 'gd',
    code: '1473',
  },
  {
    name: 'Guadeloupe',
    iso: 'gp',
    code: '590',
  },
  {
    name: 'Guam',
    iso: 'gu',
    code: '1671',
  },
  {
    name: 'Guatemala',
    iso: 'gt',
    code: '502',
  },
  {
    name: 'Guinea',
    iso: 'gn',
    code: '224',
  },
  {
    name: 'Guinea-Bissau',
    iso: 'gw',
    code: '245',
  },
  {
    name: 'Guyana',
    iso: 'gy',
    code: '592',
  },
  {
    name: 'Haiti',
    iso: 'ht',
    code: '509',
  },
  {
    name: 'Honduras',
    iso: 'hn',
    code: '504',
  },
  {
    name: 'Hong Kong',
    iso: 'hk',
    code: '852',
  },
  {
    name: 'Hungary',
    iso: 'hu',
    code: '36',
  },
  {
    name: 'Iceland',
    iso: 'is',
    code: '354',
  },
  {
    name: 'India',
    iso: 'in',
    code: '91',
  },
  {
    name: 'Indonesia',
    iso: 'id',
    code: '62',
  },
  {
    name: 'Iran',
    iso: 'ir',
    code: '98',
  },
  {
    name: 'Iraq',
    iso: 'iq',
    code: '964',
  },
  {
    name: 'Ireland',
    iso: 'ie',
    code: '353',
  },
  {
    name: 'Israel',
    iso: 'il',
    code: '972',
  },
  {
    name: 'Italy',
    iso: 'it',
    code: '39',
  },
  {
    name: 'Jamaica',
    iso: 'jm',
    code: '1876',
  },
  {
    name: 'Japan',
    iso: 'jp',
    code: '81',
  },
  {
    name: 'Jordan',
    iso: 'jo',
    code: '962',
  },
  {
    name: 'Kazakhstan',
    iso: 'kz',
    code: '7',
  },
  {
    name: 'Kenya',
    iso: 'ke',
    code: '254',
  },
  {
    name: 'Kiribati',
    iso: 'ki',
    code: '686',
  },
  {
    name: 'Kuwait',
    iso: 'kw',
    code: '965',
  },
  {
    name: 'Kyrgyzstan',
    iso: 'kg',
    code: '996',
  },
  {
    name: 'Laos',
    iso: 'la',
    code: '856',
  },
  {
    name: 'Latvia',
    iso: 'lv',
    code: '371',
  },
  {
    name: 'Lebanon',
    iso: 'lb',
    code: '961',
  },
  {
    name: 'Lesotho',
    iso: 'ls',
    code: '266',
  },
  {
    name: 'Liberia',
    iso: 'lr',
    code: '231',
  },
  {
    name: 'Libya',
    iso: 'ly',
    code: '218',
  },
  {
    name: 'Liechtenstein',
    iso: 'li',
    code: '423',
  },
  {
    name: 'Lithuania',
    iso: 'lt',
    code: '370',
  },
  {
    name: 'Luxembourg',
    iso: 'lu',
    code: '352',
  },
  {
    name: 'Macau',
    iso: 'mo',
    code: '853',
  },
  {
    name: 'Macedonia',
    iso: 'mk',
    code: '389',
  },
  {
    name: 'Madagascar',
    iso: 'mg',
    code: '261',
  },
  {
    name: 'Malawi',
    iso: 'mw',
    code: '265',
  },
  {
    name: 'Malaysia',
    iso: 'my',
    code: '60',
  },
  {
    name: 'Maldives',
    iso: 'mv',
    code: '960',
  },
  {
    name: 'Mali',
    iso: 'ml',
    code: '223',
  },
  {
    name: 'Malta',
    iso: 'mt',
    code: '356',
  },
  {
    name: 'Marshall Islands',
    iso: 'mh',
    code: '692',
  },
  {
    name: 'Martinique',
    iso: 'mq',
    code: '596',
  },
  {
    name: 'Mauritania',
    iso: 'mr',
    code: '222',
  },
  {
    name: 'Mauritius',
    iso: 'mu',
    code: '230',
  },
  {
    name: 'Mexico',
    iso: 'mx',
    code: '52',
  },
  {
    name: 'Micronesia',
    iso: 'fm',
    code: '691',
  },
  {
    name: 'Moldova',
    iso: 'md',
    code: '373',
  },
  {
    name: 'Monaco',
    iso: 'mc',
    code: '377',
  },
  {
    name: 'Mongolia',
    iso: 'mn',
    code: '976',
  },
  {
    name: 'Montenegro',
    iso: 'me',
    code: '382',
  },
  {
    name: 'Montserrat',
    iso: 'ms',
    code: '1664',
  },
  {
    name: 'Morocco',
    iso: 'ma',
    code: '212',
  },
  {
    name: 'Mozambique',
    iso: 'mz',
    code: '258',
  },
  {
    name: 'Myanmar',
    iso: 'mm',
    code: '95',
  },
  {
    name: 'Namibia',
    iso: 'na',
    code: '264',
  },
  {
    name: 'Nauru',
    iso: 'nr',
    code: '674',
  },
  {
    name: 'Nepal',
    iso: 'np',
    code: '977',
  },
  {
    name: 'Netherlands',
    iso: 'nl',
    code: '31',
  },
  {
    name: 'New Caledonia',
    iso: 'nc',
    code: '687',
  },
  {
    name: 'New Zealand',
    iso: 'nz',
    code: '64',
  },
  {
    name: 'Nicaragua',
    iso: 'ni',
    code: '505',
  },
  {
    name: 'Niger',
    iso: 'ne',
    code: '227',
  },
  {
    name: 'Nigeria',
    iso: 'ng',
    code: '234',
  },
  {
    name: 'Niue',
    iso: 'nu',
    code: '683',
  },
  {
    name: 'Norfolk Island',
    iso: 'nf',
    code: '672',
  },
  {
    name: 'North Korea',
    iso: 'kp',
    code: '850',
  },
  {
    name: 'Northern Mariana Islands',
    iso: 'mp',
    code: '1670',
  },
  {
    name: 'Norway',
    iso: 'no',
    code: '47',
  },
  {
    name: 'Oman',
    iso: 'om',
    code: '968',
  },
  {
    name: 'Pakistan',
    iso: 'pk',
    code: '92',
  },
  {
    name: 'Palau',
    iso: 'pw',
    code: '680',
  },
  {
    name: 'Palestine',
    iso: 'ps',
    code: '970',
  },
  {
    name: 'Panama',
    iso: 'pa',
    code: '507',
  },
  {
    name: 'Papua New Guinea',
    iso: 'pg',
    code: '675',
  },
  {
    name: 'Paraguay',
    iso: 'py',
    code: '595',
  },
  {
    name: 'Peru',
    iso: 'pe',
    code: '51',
  },
  {
    name: 'Philippines',
    iso: 'ph',
    code: '63',
  },
  {
    name: 'Poland',
    iso: 'pl',
    code: '48',
  },
  {
    name: 'Portugal',
    iso: 'pt',
    code: '351',
  },
  {
    name: 'Puerto Rico',
    iso: 'pr',
    code: '1',
  },
  {
    name: 'Qatar',
    iso: 'qa',
    code: '974',
  },
  {
    name: 'RÃ©union',
    iso: 're',
    code: '262',
  },
  {
    name: 'Romania',
    iso: 'ro',
    code: '40',
  },
  {
    name: 'Russia',
    iso: 'ru',
    code: '7',
  },
  {
    name: 'Rwanda',
    iso: 'rw',
    code: '250',
  },
  {
    name: 'Saint BarthÃ©lemy',
    iso: 'bl',
    code: '590',
  },
  {
    name: 'Saint Helena',
    iso: 'sh',
    code: '290',
  },
  {
    name: 'Saint Kitts and Nevis',
    iso: 'kn',
    code: '1869',
  },
  {
    name: 'Saint Lucia',
    iso: 'lc',
    code: '1758',
  },
  {
    name: 'Saint Martin',
    iso: 'mf',
    code: '590',
  },
  {
    name: 'Saint Pierre and Miquelon',
    iso: 'pm',
    code: '508',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    iso: 'vc',
    code: '1784',
  },
  {
    name: 'Samoa',
    iso: 'ws',
    code: '685',
  },
  {
    name: 'San Marino',
    iso: 'sm',
    code: '378',
  },
  {
    name: 'SÃ£o TomÃ© and PrÃ­ncipe',
    iso: 'st',
    code: '239',
  },
  {
    name: 'Saudi Arabia',
    iso: 'sa',
    code: '966',
  },
  {
    name: 'Senegal',
    iso: 'sn',
    code: '221',
  },
  {
    name: 'Serbia',
    iso: 'rs',
    code: '381',
  },
  {
    name: 'Seychelles',
    iso: 'sc',
    code: '248',
  },
  {
    name: 'Sierra Leone',
    iso: 'sl',
    code: '232',
  },
  {
    name: 'Sint Maarten',
    iso: 'sx',
    code: '1721',
  },
  {
    name: 'Slovakia',
    iso: 'sk',
    code: '421',
  },
  {
    name: 'Slovenia',
    iso: 'si',
    code: '386',
  },
  {
    name: 'Solomon Islands',
    iso: 'sb',
    code: '677',
  },
  {
    name: 'Somalia',
    iso: 'so',
    code: '252',
  },
  {
    name: 'South Africa',
    iso: 'za',
    code: '27',
  },
  {
    name: 'South Korea',
    iso: 'kr',
    code: '82',
  },
  {
    name: 'South Sudan',
    iso: 'ss',
    code: '211',
  },
  {
    name: 'Spain',
    iso: 'es',
    code: '34',
  },
  {
    name: 'Sri Lanka',
    iso: 'lk',
    code: '94',
  },
  {
    name: 'Sudan',
    iso: 'sd',
    code: '249',
  },
  {
    name: 'Suriname',
    iso: 'sr',
    code: '597',
  },
  {
    name: 'Swaziland',
    iso: 'sz',
    code: '268',
  },
  {
    name: 'Sweden',
    iso: 'se',
    code: '46',
  },
  {
    name: 'Switzerland',
    iso: 'ch',
    code: '41',
  },
  {
    name: 'Syria',
    iso: 'sy',
    code: '963',
  },
  {
    name: 'Taiwan',
    iso: 'tw',
    code: '886',
  },
  {
    name: 'Tajikistan',
    iso: 'tj',
    code: '992',
  },
  {
    name: 'Tanzania',
    iso: 'tz',
    code: '255',
  },
  {
    name: 'Thailand',
    iso: 'th',
    code: '66',
  },
  {
    name: 'Timor-Leste',
    iso: 'tl',
    code: '670',
  },
  {
    name: 'Togo',
    iso: 'tg',
    code: '228',
  },
  {
    name: 'Tokelau',
    iso: 'tk',
    code: '690',
  },
  {
    name: 'Tonga',
    iso: 'to',
    code: '676',
  },
  {
    name: 'Trinidad and Tobago',
    iso: 'tt',
    code: '1868',
  },
  {
    name: 'Tunisia',
    iso: 'tn',
    code: '216',
  },
  {
    name: 'Turkey',
    iso: 'tr',
    code: '90',
  },
  {
    name: 'Turkmenistan',
    iso: 'tm',
    code: '993',
  },
  {
    name: 'Turks and Caicos Islands',
    iso: 'tc',
    code: '1649',
  },
  {
    name: 'Tuvalu',
    iso: 'tv',
    code: '688',
  },
  {
    name: 'U.S. Virgin Islands',
    iso: 'vi',
    code: '1340',
  },
  {
    name: 'Uganda',
    iso: 'ug',
    code: '256',
  },
  {
    name: 'Ukraine',
    iso: 'ua',
    code: '380',
  },
  {
    name: 'United Arab Emirates',
    iso: 'ae',
    code: '971',
  },
  {
    name: 'United Kingdom',
    iso: 'gb',
    code: '44',
  },
  {
    name: 'United States',
    iso: 'us',
    code: '1',
  },
  {
    name: 'Uruguay',
    iso: 'uy',
    code: '598',
  },
  {
    name: 'Uzbekistan',
    iso: 'uz',
    code: '998',
  },
  {
    name: 'Vanuatu',
    iso: 'vu',
    code: '678',
  },
  {
    name: 'Vatican City',
    iso: 'va',
    code: '39',
  },
  {
    name: 'Venezuela',
    iso: 've',
    code: '58',
  },
  {
    name: 'Vietnam',
    iso: 'vn',
    code: '84',
  },
  {
    name: 'Wallis and Futuna',
    iso: 'wf',
    code: '681',
  },
  {
    name: 'Yemen',
    iso: 'ye',
    code: '967',
  },
  {
    name: 'Zambia',
    iso: 'zm',
    code: '260',
  },
  {
    name: 'Zimbabwe',
    iso: 'zw',
    code: '263',
  },
];

export class CommonService {
  getSlugFromUrl = () => {
    if (window) {
      const parts = window.location.hostname.split('.');
      if (parts[1] && parts[1] === 'tadaa' && parts[2] && parts[2] === 'express') {
        return 'tadaaexpress';
      }
      return parts[1] && ['localhost', 'book', 'booking'].some((val) => parts[1].includes(val)) ? parts[0] : 'yojee';
    }

    return null;
  };

  static getCountryList = () => {
    return countries;
  };

  static getPhoneCodeList = () => {
    const phoneCodes = [];

    countries.forEach((country) => {
      if (!phoneCodes.includes(country.code)) {
        phoneCodes.push(country.code);
      }
    });

    return phoneCodes.sort();
  };

  static findCountryByIsoCode = (iso) => {
    const _availableCountries = countries;

    const _lowerCaseIso = iso.toLowerCase();
    return _availableCountries.find((countryPhone) => {
      return countryPhone.iso === _lowerCaseIso || countryPhone.name.toLowerCase() === _lowerCaseIso;
    });
  };

  static findCountryPhoneCode = (iso) => {
    const _availableCountryPhones = countries;

    if (!iso) {
      return `+${_availableCountryPhones[0].code}`;
    }

    const _country = iso.toLowerCase();
    const _countryPhone = _availableCountryPhones.find((countryPhone) => {
      return countryPhone.name.toLowerCase() === _country || countryPhone.iso === _country;
    });

    return `+${_countryPhone ? _countryPhone.code : _availableCountryPhones[0].code}`;
  };

  static setFavicon = (favicons) => {
    if (!favicons) return;

    const defaultFavicon = document.querySelector("link[rel*='icon']");
    const head = document.getElementsByTagName('head')[0];
    if (defaultFavicon) {
      head.removeChild(defaultFavicon);
    }
    Object.keys(favicons).forEach((sizes) => {
      const link = document.createElement('link');
      link.type = 'image/png';
      link.rel = 'shortcut icon';
      link.href = favicons[sizes];
      link.setAttribute('sizes', sizes);
      head.appendChild(link);
      return null;
    });
  };

  static isServiceTypeMultileg = (serviceTypeObject) => {
    if (!serviceTypeObject) {
      return false;
    }
    return serviceTypeObject?.point2point === false;
  };
}

export const commonService = new CommonService();
