import { call, put, takeLatest } from 'redux-saga/effects';

import { landingService } from '../../services/landingService';

export default function* sagas() {
  yield takeLatest('REQUEST_GET_BOOKING_INFO', getBookingInfo);
  yield takeLatest('REQUEST_INSTANT_QUOTE_PUBLIC', getInstantQuotePublic);
  yield takeLatest('REQUEST_SENDER_INSTANT_QUOTE', getInstantQuoteSender);
  yield takeLatest('CLEAR_INSTANT_QUOTE', clearInstantQuote);
  yield takeLatest('REQUEST_GET_ORDER_TRACK', getTrackOrder);
}

function* getBookingInfo() {
  try {
    const data = yield call(landingService.getBookingInfo);
    yield put({ type: 'GET_BOOKING_INFO_SUCCESSFUL', data });
  } catch (error) {
    yield put({ type: 'GET_BOOKING_INFO_FAILED', error });
  }
}

function* getInstantQuotePublic(action) {
  yield put({ type: 'START_LOADING', loadingAction: 'getInstantQuote' });
  try {
    const data = yield call(landingService.getInstantQuotePublic, action.payload);
    yield put({ type: 'GET_INSTANT_QUOTE_SUCCESSFUL', data });
  } catch (error) {
    yield put({ type: 'GET_INSTANT_QUOTE_FAILED', error });
  }
}
function* clearInstantQuote() {
  yield put({ type: 'CLEAR_INSTANT_QUOTE_DATA' });
}

function* getInstantQuoteSender(action) {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'getInstantQuote' });
    const data = yield call(landingService.getInstantQuoteSender, action.payload);
    yield put({ type: 'GET_INSTANT_QUOTE_SUCCESSFUL', data });
  } catch (error) {
    yield put({ type: 'GET_INSTANT_QUOTE_FAILED', error });
  }
}

function* getTrackOrder(action) {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'getTrackInfo' });
    const data = yield call(landingService.getOrderTrackingData, action.payload);
    yield put({ type: 'GET_ORDER_TRACK', data });
  } catch (error) {
    yield put({ type: 'GET_ORDER_TRACK_FAILED', error });
    try {
      const data = yield call(landingService.getItemTrackingData, action.payload);
      yield put({ type: 'GET_ITEM_TRACK', data });
    } catch (error) {
      yield put({ type: 'GET_ITEM_TRACK_FAILED', error });
    }
  }
}
