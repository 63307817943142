import { IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect, useState } from 'react';
import { Translation } from 'react-i18next';

const styles = () => ({
  inputLabel: {
    fontFamily: 'Arial',
    fontSize: '14px',
  },
});

const SearchBox = (props) => {
  const { onSearch, classes, debounceTime } = props;
  const [searchText, setSearchField] = useState('');

  const _onSearch = (text = null) => {
    onSearch(text === null ? searchText : text);
  };

  const _onClear = () => {
    setSearchField('');
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => _onSearch(searchText), debounceTime);
    return () => clearTimeout(delayDebounceFn);
  }, [searchText, _onSearch]);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Translation>
            {(t) => (
              <TextField
                inputProps={{
                  style: {
                    padding: 10,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className="custom-field-address-id-adornment" position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: searchText && (
                    <IconButton
                      className="custom-field-address-id-adornment"
                      size="small"
                      aria-label="toggle password visibility"
                      onClick={_onClear}
                    >
                      <CancelRoundedIcon />
                    </IconButton>
                  ),
                  classes: {
                    input: classes.inputLabel,
                  },
                }}
                variant="outlined"
                margin="dense"
                onChange={(event) => setSearchField(event.target.value)}
                value={searchText || ''}
                placeholder={t('Search')}
              />
            )}
          </Translation>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(SearchBox);
