import i18next from 'i18next';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { CommonService } from '../../services/commonService';
import { companyService } from '../../services/companyService';
export const loginStoreData = (state) => state.login;

export default function* sagas() {
  yield takeLatest('REQUEST_GET_COMPANY_INFO', getCompanyInfo);
  yield takeLatest('REQUEST_GET_STRIPE_API_KEY', getStripeApiKey);
  yield takeLatest('RESET_ERRORS', resetErrors);
}

function* getCompanyInfo() {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'getCompanyInfo' });
    const { accountInfo } = yield select(loginStoreData);
    const slug = accountInfo.slug;
    const companyData = yield call(companyService.getCompanyInfo, slug);

    yield put({ type: 'GET_COMPANY_INFO_SUCCESSFUL', companyData });
    //	set company branding
    if (companyData.branding) {
      CommonService.setFavicon(companyData.branding.favicon);
      const branding = yield call(companyService.getCompanyBranding, companyData.branding);
      if (branding) {
        yield put({ type: 'SET_COMPANY_BRANDING', branding });
      }
    }

    const { sender } = companyData;
    //	set language
    if (sender && sender.language) {
      i18next.changeLanguage(sender.language);
    }

    const { requiresPayment } = sender ? sender : null;
    const { senderType } = accountInfo;
    //	set isRequirePayment
    const isRequirePayment =
      requiresPayment && senderType && requiresPayment[senderType] && sender.orderPriceSource === 'api';
    yield put({ type: 'SET_IS_REQUIRE_PAYMENT', isRequirePayment });
  } catch (error) {
    yield put({ type: 'GET_COMPANY_INFO_FAILED', error });
  }
}

function* getStripeApiKey() {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'getStripeApiKey' });
    const { accountInfo } = yield select(loginStoreData);
    if (accountInfo?.slug) {
      const { slug } = accountInfo;
      const { stripe_key } = yield call(companyService.getStripeApiKey, slug);
      yield put({ type: 'GET_STRIPE_API_KEY_SUCCESSFUL', stripeApiKey: stripe_key });
    }
  } catch (error) {
    yield put({ type: 'GET_STRIPE_API_KEY_FAILED', error });
  }
}

function* resetErrors() {
  yield put({ type: 'GET_RESET_ERRORS' });
}
