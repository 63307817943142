import { Box, Button, LinearProgress, styled, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { TIMEZONE_FIELD } from '@yojee/helpers/constants';
import { fromErrorToMessage, getErrorsInClearStructure } from '@yojee/helpers/errorHelper';
import { UploadIcon } from '@yojee/ui/common/icons';
import ListTimeZoneDialog from '@yojee/ui/timezone/ListTimeZoneDialog';

import { v4ResetBatchUploadData } from '../../../../sagas/v4BatchOrder/batchOrderActions';

const StyledDropzoneContainer = styled(Box)({
  minHeight: 130,
  backgroundImage: `url(
    "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%231BACE0' stroke-width='1' stroke-dasharray='6' stroke-dashoffset='0' rx='4' ry='4' stroke-linecap='square'/%3e%3c/svg%3e"
  )`,
  borderRadius: '4px',
  display: 'flex',

  width: '98%',
});

const StyledUploadedFileDetail = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  flex: '1 1 0%',
  padding: 25,
});

const StyledDropzoneContent = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  flex: '1 1 0%',
});

const StyledTypography = styled(Typography)({
  fontSize: '14px',

  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});

const StyledSecondaryTypography = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  color: theme.color.grey02,
}));

const MAX_FILE_SIZE_IN_BYTE = 5242880;

const TimezoneErrorMessage = ({ error }) => {
  const rowMessage = error?.row ? `Row ${error?.row}` : '';
  const columnMessage = error?.col ? `Column ${error?.col}` : '';
  const [openTimeZoneDialog, setOpenTimeZoneDialog] = useState(false);

  return (
    <Box>
      <Box>
        {`${rowMessage}, ${columnMessage}:`} <Trans>Unable to match timezone, click </Trans>
        <Box
          onClick={() => setOpenTimeZoneDialog(true)}
          sx={{
            textTransform: 'underline',
            fontWeight: 'bold',
            cursor: 'pointer',
            display: 'inline',
          }}
        >
          <Trans>Here</Trans>
        </Box>
        <Trans> to find the correct timezone and paste it into your file</Trans>
      </Box>
      <ListTimeZoneDialog onClose={() => setOpenTimeZoneDialog(false)} open={openTimeZoneDialog} />
    </Box>
  );
};

const Zone = ({ uploadedFile, selectedTemplateDetail, setUploadedFile }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const batchState = useSelector((state) => state.v4BatchOrder.state);
  const errors = useSelector((state) => state.v4BatchOrder.errors);

  const { t } = useTranslation();

  const handleDropAccepted = (acceptedFiles) => {
    setUploadedFile(acceptedFiles[0]);
  };

  const handleRemoveUploadedFile = () => {
    setUploadedFile(undefined);
  };

  const handleNewBatchUpload = () => {
    setUploadedFile(undefined);

    dispatch(v4ResetBatchUploadData());
  };

  const handleDropRejected = (rejectedFiles) => {
    const { errors } = rejectedFiles[0];

    if (errors[0].code === 'file-too-large') {
      enqueueSnackbar(<Trans>File is larger than 5MB</Trans>, {
        variant: 'error',
      });

      return;
    }

    if (errors[0].code === 'file-invalid-type') {
      enqueueSnackbar(<Trans>This file is not supported, please choose a valid one (XLSX or CSV)</Trans>, {
        variant: 'error',
      });
      return;
    }
  };

  const renderErrors = () => {
    return typeof errors === 'string' ? (
      <Typography>{errors}</Typography>
    ) : (
      <ul>
        {getErrorsInClearStructure(errors).map((error, index) => {
          return (
            <li key={index}>
              {error.field === TIMEZONE_FIELD ? (
                <TimezoneErrorMessage error={error} />
              ) : (
                fromErrorToMessage(error, selectedTemplateDetail?.fields_schema)
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  const renderFileDetail = () => {
    switch (batchState) {
      case 'processing':
        return (
          <Box display="flex" flexDirection="column">
            <Box width="100%" px="16px" pb={0} pt="25px">
              <LinearProgress />
            </Box>
            <StyledUploadedFileDetail pt="0px !important">
              <StyledTypography>{uploadedFile.name}</StyledTypography>
              <Button onClick={handleRemoveUploadedFile}>Remove</Button>
            </StyledUploadedFileDetail>
          </Box>
        );

      case 'completed':
        return (
          <StyledUploadedFileDetail>
            <StyledTypography>
              <Trans>File uploaded successfully. Click here to go to</Trans>
              <Box
                onClick={() => {
                  if (pathname === '/order-tracking') {
                    navigate(0);
                    return;
                  }
                  navigate('/order-tracking');
                }}
                sx={{
                  textDecoration: 'underline',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  color: 'primary.main',
                }}
              >
                <Trans>Order Tracking</Trans>
              </Box>
            </StyledTypography>
            <Button onClick={handleNewBatchUpload}>{t('New')}</Button>
          </StyledUploadedFileDetail>
        );

      case 'missing_info':
        return (
          <StyledUploadedFileDetail>
            <StyledTypography>{t('File upload missing info')}</StyledTypography>
            <Button onClick={handleNewBatchUpload}>{t('New')}</Button>
          </StyledUploadedFileDetail>
        );

      case 'failed':
        return (
          <Box pb="25px">
            <StyledUploadedFileDetail pb="0px !important">
              <StyledTypography>{t('File upload failed')}</StyledTypography>
              <Button onClick={handleNewBatchUpload}>{t('New')}</Button>
            </StyledUploadedFileDetail>

            {!!errors && renderErrors()}
          </Box>
        );

      case 'not_submitted':
      default:
        return (
          <StyledUploadedFileDetail>
            <StyledTypography>{uploadedFile.name}</StyledTypography>
            <Button onClick={handleRemoveUploadedFile}>Remove</Button>
          </StyledUploadedFileDetail>
        );
    }
  };

  return (
    <Box height="100%">
      <StyledDropzoneContainer>
        {uploadedFile ? (
          <Box display="flex" flexDirection="column" justifyContent="center" width="100%">
            {renderFileDetail()}
          </Box>
        ) : (
          <Dropzone
            onDropAccepted={handleDropAccepted}
            onDropRejected={handleDropRejected}
            accept=".csv, application/vnd.ms-excel, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xlsx"
            maxSize={MAX_FILE_SIZE_IN_BYTE}
            multiple={false}
          >
            {({ getRootProps, getInputProps }) => (
              <StyledDropzoneContent {...getRootProps()}>
                <input {...getInputProps()} />
                <Box display="flex" alignItems="center">
                  <UploadIcon sx={{ transform: 'scale(0.7)', marginRight: '10px' }} />
                  <Typography color="primary.main" fontSize="14px">
                    <Trans>Drag or drop your file here or </Trans>

                    <span
                      style={{
                        textDecoration: 'underline',
                        fontWeight: 'bold',
                      }}
                    >
                      <Trans>choose</Trans>
                    </span>

                    <Trans> to upload</Trans>
                  </Typography>
                </Box>
              </StyledDropzoneContent>
            )}
          </Dropzone>
        )}
      </StyledDropzoneContainer>

      <Box display="flex" justifyContent="space-between" mt="8px" width="98%">
        <StyledSecondaryTypography>{t('Files supported XLSX, CSV')}</StyledSecondaryTypography>
        <StyledSecondaryTypography>{t('Maximum size: 5MB')}</StyledSecondaryTypography>
      </Box>
    </Box>
  );
};

export default Zone;
