import '../../styles/components/Login/loginDialog.scss';

import Dialog from '@material-ui/core/Dialog';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';

import * as loginActions from '../../sagas/login/loginActions';
import { CommonService } from '../../services/commonService';
import {
  isIncludeNumber,
  isIncludeSpecialChars,
  isLowerCase,
  isNumber,
  isUpperCase,
  isValidEmail,
  isValidOtp,
  isValidPassword,
} from '../../utils';
import LoginForm from './LoginForm';

class LoginDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      phoneNumber: '',
      otpCode: '',
      loginMode: 'email',
      phoneCodeList: CommonService.getPhoneCodeList(),
      emailLoginForm: {
        email: '',
        password: '',
        validators: {
          email: ['email'],
        },
        errors: {
          email: [],
        },
        touched: {
          email: false,
        },
      },
      getOtpForm: {
        countryCode: '',
        phone: '',
        validators: {
          phone: ['number'],
        },
        errors: {
          phone: [],
        },
        touched: {
          phone: false,
        },
      },
      otpConfirmationForm: {
        countryCode: '',
        phone: '',
        otp: '',
        validators: {
          phone: ['number'],
          otp: ['otp'],
        },
        errors: {
          phone: [],
          otp: [],
        },
        touched: {
          phone: false,
          otp: false,
        },
      },
      signUpForm: {
        email: '',
        password: '',
        name: '',
        countryCode: '',
        phone: '',
        termsAgreed: false,
        validators: {
          email: ['email'],
          password: ['password'],
          phone: ['number'],
        },
        errors: {
          email: [],
          password: [],
          phone: [],
        },
        touched: {
          email: false,
          password: false,
          phone: false,
        },
      },
    };
  }

  onFieldChange = (form, field) => (event) => {
    const _target = event.target;
    const value = _target.type === 'checkbox' ? _target.checked : _target.value;
    this.setState(
      {
        [form]: {
          ...this.state[form],
          [field]: value,
        },
      },
      () => this.validateField(form, field, value)
    );
  };

  onFieldBlur = (form, field) => (event) => {
    this.setState({
      [form]: {
        ...this.state[form],
        touched: {
          ...this.state[form].touched,
          [field]: true,
        },
      },
    });
  };

  validateField = (form, field, value) => {
    if (this.state[form].validators[field]) {
      const errors = [];
      this.state[form].validators[field].forEach((validator) => {
        switch (validator) {
          case 'email':
            if (!isValidEmail(value)) {
              errors.push(<Trans key="invalid-email">Invalid Email</Trans>);
            }
            break;
          case 'password':
            if (!value) {
              errors.push(<Trans key={`${field}-error-validator`} i18nKey="Field is required" />);
            }
            if (!isValidPassword(value)) {
              errors.push(<Trans key={`${field}-error-validator-password-length`} i18nKey="password-length-require" />);
            }

            if (!isLowerCase(value)) {
              errors.push(<Trans key={`${field}-error-validator-lower-case`} i18nKey="invalid-lower-case" />);
            }

            if (!isUpperCase(value)) {
              errors.push(<Trans key={`${field}-error-validator-upper-case`} i18nKey="invalid-upper-case" />);
            }

            if (!isIncludeNumber(value)) {
              errors.push(<Trans key={`${field}-error-validator-include-number`} i18nKey="invalid-include-number" />);
            }

            if (!isIncludeSpecialChars(value)) {
              errors.push(<Trans key={`${field}-error-validator-special-chars`} i18nKey="invalid-special-chars" />);
            }
            break;
          case 'phone':
            if (!isNumber(value)) {
              errors.push(<Trans key="invalid-phone-number">Phone number should contain only digits</Trans>);
            }
            break;
          case 'otp':
            if (!isValidOtp(value)) {
              errors.push(<Trans key="invalid-otp">OTP is invalid</Trans>);
            }
            break;
        }
      });

      this.setState({
        [form]: {
          ...this.state[form],
          errors: {
            ...this.state[form].errors,
            [field]: errors,
          },
        },
      });
    }
  };

  closeDialog = () => {
    this.props.hideLoginDialog();
  };

  render() {
    return (
      <Dialog
        open={this.props.isShowLoginDialog}
        className="login-dialog-container"
        maxWidth={false}
        scroll="body"
        data-cy="login-signup-form"
      >
        <LoginForm showClose={true} closeDialog={this.closeDialog} showRegisterLink={true} />
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.login.error,
    loading: state.login.loading,
    isShowLoginDialog: state.login.componentState.isShowLoginDialog,
    termsAndConditionsUrl: state.company.companyInfo.branding.termsAndConditionsUrl,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideLoginDialog: () => dispatch(loginActions.hideLoginDialog()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginDialog);
