import { authService } from '../authService/index';
import { BookingBaseService } from '../baseService/bookingBaseService';

export class SenderBatchOrderService extends BookingBaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  uploadBatchFile = ({ file, format, uploaderId, templateId }) => {
    const _formData = new FormData();

    _formData.set('file', file);
    _formData.set('format', format);
    if (uploaderId) {
      _formData.set('uploader_id', uploaderId);
    }
    if (templateId) {
      _formData.set('template_id', templateId);
    }

    return this.authService
      .post(this.getUmbrellaApiUrlV4('sender/orders/batch'), _formData, { 'Content-Type': 'multipart/form-data' })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  checkBatchUploadStatus = (batchId) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`sender/orders/batch/${batchId}/status`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getOrdersOfBatchID = (batchId) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`sender/orders/batch/${batchId}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };
}

export const senderBatchOrderServiceV4 = new SenderBatchOrderService({
  authService,
});
