import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Trans } from 'react-i18next';

import ManageCustomDialog from '@yojee/ui/common/CustomDialog/ManageCustomDialog';

import TimeZoneContent from './TimeZoneContent';

const styles = {
  titleClass: {
    textTransform: 'uppercase',
    fontFamily: 'Arial',
    fontSize: '16px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#2a3236',
    paddingBottom: '0px',
  },
  subTitleClass: {
    fontFamily: 'Arial',
    fontSize: '15px',
    fontStyle: 'normal',
    color: '#2a3236',
    paddingTop: '20px',
  },
};

const ListTimeZoneDialog = (props) => {
  const { classes } = props;

  const handleClose = () => {
    props.onClose();
  };

  const _renderTitle = () => {
    return (
      <div className={classes.titleClass}>
        <Trans>BATCH UPLOAD - TIMEZONE</Trans>
      </div>
    );
  };

  const _renderSubTitle = () => {
    return (
      <div className={classes.subTitleClass}>
        <Trans>Find the correct timezone, copy and paste it into your batch upload and upload the file again.</Trans>
      </div>
    );
  };

  return (
    <ManageCustomDialog
      className="timezone-dialog"
      open={props.open}
      onClose={handleClose}
      title={_renderTitle()}
      subTitle={_renderSubTitle()}
    >
      <TimeZoneContent />
    </ManageCustomDialog>
  );
};

export default withStyles(styles)(ListTimeZoneDialog);
