import { SvgIcon, SvgIconProps } from '@mui/material';

const UploadIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    width="16px"
    height="17px"
    viewBox="0 0 16 17"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>Icon/Upload</title>
    <g id="Icon/Upload" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M1,17 C0.44771525,17 0,16.5522847 0,16 L0,11 C0,10.4477153 0.44771525,10 1,10 C1.55228475,10 2,10.4477153 2,11 L2,14.999 L14,15 L14,11 C14,10.4477153 14.4477153,10 15,10 C15.5522847,10 16,10.4477153 16,11 L16,16 C16,16.5128358 15.6139598,16.9355072 15.1166211,16.9932723 L15,17 L1,17 Z"
        id="Path"
        fill="#1BACE0"
      />
      <path
        d="M8.05629929,0 C8.28128577,0.0135919017 8.50248036,0.108735214 8.67438036,0.285429936 L12.7206625,4.44457319 C13.0931125,4.82741176 13.0931125,5.44811571 12.7206625,5.83095428 C12.3482125,6.21379284 11.7443518,6.21379284 11.3719018,5.83095428 L8.95432888,3.34573975 L8.95371785,11.0196805 C8.95371785,11.561096 8.52672382,12 8,12 C7.47327618,12 7.04628215,11.561096 7.04628215,11.0196805 L7.04689319,3.34573975 L4.62809821,5.83095428 C4.25564822,6.21379284 3.65178748,6.21379284 3.27933749,5.83095428 C2.9068875,5.44811571 2.9068875,4.82741176 3.27933749,4.44457319 L7.32561964,0.285429936 C7.46886964,0.138184334 7.64635199,0.0475716561 7.83173412,0.0135919017 L7.94370071,0 L8.05629929,0 Z"
        id="Path"
        fill="#1BACE0"
      />
    </g>
  </SvgIcon>
);

export default UploadIcon;
