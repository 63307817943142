import { SvgIcon, SvgIconProps } from '@mui/material';

const BookingFormIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="36px"
      height="32px"
      viewBox="0 0 36 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>Icon/Booking Form</title>
      <g id="Icon/Booking-Form" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M34,0 C35.1045695,0 36,0.8954305 36,2 L36,6 C36,7.1045695 35.1045695,8 34,8 L2,8 C0.8954305,8 0,7.1045695 0,6 L0,2 C0,0.8954305 0.8954305,0 2,0 L34,0 Z M34,2 L2,2 L2,6 L34,6 L34,2 Z M14,12 C15.1045695,12 16,12.8954305 16,14 L16,18 C16,19.1045695 15.1045695,20 14,20 L2,20 C0.8954305,20 0,19.1045695 0,18 L0,14 C0,12.8954305 0.8954305,12 2,12 L14,12 Z M14,14 L2,14 L2,18 L14,18 L14,14 Z M34,12 C35.1045695,12 36,12.8954305 36,14 L36,18 C36,19.1045695 35.1045695,20 34,20 L22,20 C20.8954305,20 20,19.1045695 20,18 L20,14 C20,12.8954305 20.8954305,12 22,12 L34,12 Z M34,14 L22,14 L22,18 L34,18 L34,14 Z M21,24 L35,24 C35.5522847,24 36,24.4477153 36,25 L36,31 C36,31.5522847 35.5522847,32 35,32 L21,32 C20.4477153,32 20,31.5522847 20,31 L20,25 C20,24.4477153 20.4477153,24 21,24 Z"
          id="Icon-Form"
          fill="#1BACE0"
          fillRule="nonzero"
        />
      </g>
    </SvgIcon>
  );
};

export default BookingFormIcon;
