import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as featureManagementActions from '@yojee/ui/feature-management/saga/actions';

import * as companyActions from '../sagas/company/companyActions';
import * as loginActions from '../sagas/login/loginActions';

const BookingPageContentHoc = ({ children }) => {
  const dispatch = useDispatch();
  const accountInfo = useSelector((state) => state.login.accountInfo);

  useEffect(() => {
    dispatch(companyActions.getCompanyInfo());
    dispatch(featureManagementActions.fetchReleaseToggles());
  }, [dispatch]);

  useEffect(() => {
    if ((!accountInfo.id && accountInfo.accessToken) || accountInfo.id) {
      dispatch(loginActions.getSenderInfo());
    }
  }, [dispatch]);

  return children;
};

export default BookingPageContentHoc;
