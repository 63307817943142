export const getCompanyInfo = () => {
  return { type: 'REQUEST_GET_COMPANY_INFO' };
};

export const getStripeApiKey = () => {
  return { type: 'REQUEST_GET_STRIPE_API_KEY' };
};

export const resetErrors = () => {
  return { type: 'RESET_ERRORS' };
};
