import { createTheme } from '@mui/material/styles';

type Color = {
  grey100: string;
  grey02: string;
  grey03: string;
  grey04: string;
  grey05: string;
  grey06: string;
};

// Expand `PaletteColorOptions` to include `tint` variant.
// [1] - https://mui.com/material-ui/customization/palette/#typescript-2
declare module '@mui/material/styles' {
  interface PaletteColor {
    tint?: string;
  }

  interface SimplePaletteColorOptions {
    tint?: string;
  }

  interface Theme {
    color: Color;
  }
  interface ThemeOptions {
    color?: Color;
  }
}

export default createTheme({
  typography: {
    fontFamily: ['SF Pro', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: '#1BACE0',
      dark: '#168AB3',
      light: '#5FC5E9',
      tint: '#E8F7FC',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#DD5147',
      dark: '#C74940',
      light: '#E4746C',
      tint: '#FDF3F2',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#FFAF21',
      dark: '#E69E1E',
      light: '#FFBF4D',
      tint: '#FFF9F0',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#499F68',
      dark: '#428F5E',
      light: '#6DB286',
      tint: '#E8F3EC',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#499F68',
      dark: '#428F5E',
      light: '#6DB286',
      tint: '#E8F3EC',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#040921',
    },
  },
  color: {
    grey100: '#5c6873',
    grey02: '#686b7a',
    grey03: '#aeb0b8',
    grey04: '#c3c4ca',
    grey05: '#e5e6e8',
    grey06: '#f5f5f6',
  },
  // Disable SSR for `useMediaQuery` to avoid hydration mismatch.
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
});
